import React from "react";
import {Outlet} from "react-router-dom";
import styles from "./portal-layout.module.scss"
import {Backdrop, Box, CircularProgress, useTheme} from "@mui/material";
import {AppToolbar} from "../../../components/toolbar/AppToolbar";
import {AppSideNav} from "../../../components/sidenav/AppSideNav";
import {Footer} from "../../../components/footer/Footer";
import {Sidebar} from "../../../components/sidebar/Sidebar";
import {useUserDataService} from "../../../shared/hooks/services/useUserDataService";
import {vhvGrey} from "@vhv/react-mui-theme/dist";

export const PortalLayout = () => {
    const {getUsername} = useUserDataService();
    const theme = useTheme();

    const renderLayout = () => {
        if (getUsername()) {
            return <Box className={styles.appLayout}
                        bgcolor={theme.palette.mode === 'dark' ? vhvGrey[600] : vhvGrey["bg"]}>
                <Box className={styles.headerContainer}>
                    <AppToolbar/>
                </Box>
                <Box className={styles.sidenavContainer}>
                    <AppSideNav/>
                </Box>
                <Box className={styles.mainContainer}>
                    <Outlet/>
                    <Footer/>
                </Box>
                <Sidebar/>
            </Box>
        }
        return <Backdrop open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    }
    return renderLayout()
}
