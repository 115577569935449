import {CardContent, CardHeader, Divider, IconButton} from "@mui/material";
import styles from "../sidebar.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";
import * as React from "react";

export interface InfoWidgetState {
    title: string,
    content?: string
}

interface InfoWidgetProps extends InfoWidgetState {
    handleViewChange: (view: string) => void,
}

export const InfoWidget = (props: InfoWidgetProps) => {
    return <>
        <CardHeader
            className={styles.sideToolbarHeader}
            title={props.title}
            action={
                <IconButton onClick={() => props.handleViewChange("")} aria-label="settings">
                    <CloseIcon/>
                </IconButton>
            }/>
        <Divider></Divider>
        {props.content && <CardContent sx={{padding: "2rem", flexGrow: "1"}}>
            {parse(props.content)}
        </CardContent>
        }
    </>
}
