import {AnswerInput, AnswerType, AnswerValue, AnswerValueInput, CreateQuestionInput, Question, UpdateQuestionInput} from "shared/graphql/API";
import React, {ReducerState, useReducer, useState} from "react";
import {Notification} from "shared/helpers/notification/Notification";
import classNames from "classnames";
import {isNotBlank, omitGQLTypenames} from "shared/helpers/CommonUtils";

import styles from "./carousel/question-carousel.module.scss";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from "@mui/material";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import {Delete} from "@mui/icons-material";
import {useAuditData} from "shared/context/AuditEditorContext";
import {useQuestionService} from "shared/hooks/services/QuestionService";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {AnswerValuesEditor} from "../audit-answer/AnswerValuesEditor";

type AuditQuestionProps = {
    questionData?: Question,
    className?: any,
    editMode?: boolean | undefined,
    onChange?: Function,
    onCreate?: Function,
    onDelete?: Function,
}

const stateReducer = (state: Question, action: Question | any) => ({
    ...state,
    ...(typeof action === "function" ? action(state) : action),
});

export const AuditQuestionEditor = (props: AuditQuestionProps) => {
    const {questionData, className} = props;
    const {onCreate, onChange, onDelete} = props;
    const {t} = useTranslation();
    const audit = useAuditData();
    const {createQuestion, updateQuestion, deleteQuestion} = useQuestionService();
    const [question, setQuestion] = useReducer(stateReducer, questionData as ReducerState<any>);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>();
    const [values, setValues] = useState<Array<AnswerValue>>(questionData?.answer?.values || []);

    const validateInput = () => {
        return isNotBlank(question?.question) && isNotBlank(audit.id);
    }

    const getAnswerData = (): AnswerInput => {
        return {
            type: question?.answer?.type || AnswerType.text,
            values: omitGQLTypenames<AnswerValueInput>(values)
        } as AnswerInput;
    }

    const getQuestionData = () => {
        if (validateInput())
            return {
                id: question.id,
                orderID: question.orderID,
                auditId: question.auditId || audit.id,
                question: question.question,
                description: question.description,
                answer: getAnswerData(),
            }
        Notification.error("Input is not valid. Please insert at least a question and an audit ID.");
        return null;
    }

    const createAuditQuestion = () => {
        const questionData = getQuestionData() as CreateQuestionInput;
        if (questionData) {
            createQuestion(questionData)
                .then((q) => {
                    setQuestion(q);
                    onCreate && onCreate(q);
                })
                .then(() => Notification.success("Question created."))
                .catch(() => Notification.error("Question could not be created"));
        }
    }

    const updateAuditQuestion = (updateQuestionInput: UpdateQuestionInput) => {
        updateQuestion(updateQuestionInput)
            .then(q => {
                setQuestion(q);
                onChange && onChange(q);
            })
            .then(() => Notification.success("Question updated."))
            .catch(() => {
                Notification.error("Could not update question information.");
            });
    }

    const deleteAuditQuestion = () => {
        if (question?.id) {
            deleteQuestion({
                id: question?.id,
            })
                .then(() => Notification.success("Question deleted."))
                .then(() => onDelete && onDelete())
                .catch(() => Notification.error("Question could not be deleted."))
                .finally(() => setOpenDialog(false));
        } else {
            onDelete && onDelete();
        }
    }

    const updateQuestionData = (data: any) => {
        setQuestion(data);
        if (question?.id) {
            if (timer)
                window.clearTimeout(timer);
            const newTimer = window.setTimeout(() => {
                let updateData = getQuestionData() as UpdateQuestionInput;
                if (updateData) {
                    const apiInput = {...updateData, ...data};
                    updateAuditQuestion(apiInput);
                }
            }, 3000);
            setTimer(newTimer);
        }
    }

    const updateSelectionData = (data: Array<AnswerValue>) => {
        setValues(data);
        if (question?.id) {
            if (timer)
                window.clearTimeout(timer);
            const newTimer = window.setTimeout(() => {
                let updateData = getQuestionData() as UpdateQuestionInput;
                if (updateData) {
                    if (updateData.answer)
                        updateData.answer.values = omitGQLTypenames<AnswerValueInput>(data);
                    updateAuditQuestion(updateData);
                }
            }, 3000);
            setTimer(newTimer);
        }
    }

    const renderAnswerEditor = () => {
        switch (question?.answer?.type) {
            case AnswerType.text:
                return <TextField multiline value={question?.question} disabled rows={2} fullWidth/>
            case AnswerType.check:
            case AnswerType.radio:
                return <AnswerValuesEditor answerValues={values} onChange={(a: Array<AnswerValue>) => updateSelectionData(a)}/>
            default:
        }
        return <div></div>
    }

    const renderAction = () => {
        if (!question?.id) {
            return <Button className={styles.cardAction} onClick={() => createAuditQuestion()}>{t("action.create")}</Button>
        }
    }

    const renderDeleteDialog = () => {
        if (question && question.id) {
            setOpenDialog(true);
        } else if (onDelete) {
            onDelete();
        }
    }

    const renderFavouriteButton = () => {
        return <IconButton onClick={() => updateFavouriteStatus()}>
            <TurnedInIcon className={question?.favourite ? classNames(styles.favouriteIconSelected, styles.acapIcon) : classNames(styles.favouriteIconDeselected, styles.acapIcon)}/>
        </IconButton>
    }

    const renderDeleteButton = () => {
        return <IconButton color="secondary" onClick={() => renderDeleteDialog()}>
            <Delete/>
        </IconButton>
    }

    const renderQuestionEditor = () => {
        return <Card className={classNames(className, styles.card)}>
            <CardHeader action={
                <div>
                    {renderFavouriteButton()}
                    {renderDeleteButton()}
                </div>
            }/>
            <CardContent className={styles.cardContainer}>
                <div className={styles.cardColumn}>
                    <TextField label={t("content.common.question")}
                               value={question?.question}
                               multiline
                               rows={2}
                               required
                               onChange={e => updateQuestionData({question: e.target.value})}/>
                    <TextField label={t("content.common.description")}
                               value={question?.description}
                               multiline
                               rows={2}
                               required
                               onChange={e => updateQuestionData({description: e.target.value})}/>
                    <div className={styles.cardRow}>
                        <div className={styles.inputGroup}>
                            <TextField label={t("content.common.id")}
                                       value={question?.id}
                                       disabled
                            />
                            <FormControl fullWidth>
                                <InputLabel id={"answerType"}>{t("content.audit.editor.answerType")}</InputLabel>
                                <Select labelId="answerType-checkbox-label"
                                        label={t("content.audit.editor.answerType")}
                                        value={question?.answer?.type || AnswerType.text}
                                        onChange={e => updateQuestionData({answer: {...getAnswerData(), type: e.target.value}})}
                                >
                                    <MenuItem value={AnswerType.text}>{AnswerType.text}</MenuItem>
                                    <MenuItem value={AnswerType.radio}>{AnswerType.radio}</MenuItem>
                                    <MenuItem value={AnswerType.check}>{AnswerType.check}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className={styles.cardRow}>
                        {renderAnswerEditor()}
                    </div>
                    <div className={styles.cardRow}>
                        {renderAction()}
                    </div>
                </div>
            </CardContent>
            <CardActions className={styles.cardAction}>
                {lastUpdatedAt()}
            </CardActions>
            <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {t("content.audit.editor.deleteDialog.message")}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={question?.question}
                                       multiline
                                       rows={2}
                                       disabled
                                       fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={t("content.common.id")}
                                       value={question?.id}
                                       disabled
                                       fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color={"secondary"} variant={"outlined"} onClick={() => setOpenDialog(false)}>
                        {t("action.cancel")}
                    </Button>
                    <Button color={"primary"} variant={"outlined"} onClick={() => deleteAuditQuestion()}>
                        {t("action.delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    }

    const updateFavouriteStatus = () => {
        if (question) {
            const updateQuestionInput: UpdateQuestionInput = {
                favourite: !question?.favourite,
                id: question?.id,
            }
            updateAuditQuestion(updateQuestionInput);
        }
    }
    const lastUpdatedAt = () => {
        if (question?.updatedAt) {
            const i18nPrefix = t("content.common.updatedAt");
            const updatedAt = i18nPrefix + dayjs(question.updatedAt).fromNow();
            return <Tooltip title={dayjs(question.updatedAt).format().toString()}>
                <Chip className={styles.cardChip} label={updatedAt}/>
            </Tooltip>
        }
    }
    return renderQuestionEditor();
}

export default AuditQuestionEditor;

