import React, {useEffect, useState} from "react";
import {Box, IconButton, InputBase, useTheme} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";

export const SearchWidget = () => {
    const [searchParams] = useSearchParams();
    const [category, setCategory] = useState("news");
    const [searchText, setSearchText] = useState("");
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParam = searchParams.get('s');
        if (searchParam) {
            setSearchText(searchParam);
        }
    }, [searchParams]);

    useEffect(() => {
        const categoryParam = searchParams.get('c');
        if (categoryParam) {
            setCategory(categoryParam);
        }
    }, [searchParams]);

    const performSearch = () => {
        navigate({
            pathname: "search",
            search: `?${createSearchParams({
                c: category,
                s: searchText
            })}`
        });
    }

    return <Box sx={{display: "flex", height: "32px", border: "solid 1px", borderColor: theme.palette.primary.main, borderRadius: "4px", float: "none", width: "50%", margin: "0 auto"}}>
        <Select value={category} onChange={(e) => setCategory(e.target.value)}>
            <MenuItem value={"news"}>News</MenuItem>
            <MenuItem value={"initiative"}>Initiativen</MenuItem>
        </Select>
        <InputBase sx={{ml: 1, flex: 1}}
                   value={searchText}
                   placeholder="Search"
                   onChange={(e) => setSearchText(e.target.value)}
                   onKeyDown={(e) => e.key === "Enter" && performSearch()}
        />
        <IconButton onClick={performSearch}>
            <SearchIcon/>
        </IconButton>
    </Box>
}
