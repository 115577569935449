import React from "react";

import styles from "./sidenav.module.scss";
import classNames from "classnames";
import {Drawer, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import {isNotBlank} from "shared/helpers/CommonUtils";
import {NavigationRoutes, NavigationRouteType} from "app/NavigationRoutes";
import {useTranslation} from "react-i18next";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {useUserDataService} from "../../shared/hooks/services/useUserDataService";

export const AppSideNav = () => {
    const {t} = useTranslation();
    const {getGroups} = useUserDataService();
    const [open, setOpen] = React.useState(false);

    const getLinkStyles = (isActive: boolean) => {
        if (isActive)
            return classNames(styles.navLink, styles.active)
        return styles.navLink;
    }

    const cleanupPath = (path: string) => {
        return path.replace("/:id?", "");
    }

    return <Drawer variant="permanent" open={open} anchor={"left"}>
        <div onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <div className={styles.sidenav}>
                <div className={styles.sidenavContent}>
                    {NavigationRoutes
                        .filter((a): a is NavigationRouteType => a.i8nLabel !== undefined)
                        .filter(a => isNotBlank(a.i8nLabel))
                        .filter(a => {
                            if (a.groups) {
                                return a.groups.some(r => getGroups().includes(r));
                            }
                            return true;
                        })
                        .map((r) => {
                                if (r.i8nLabel) {
                                    return <NavLink key={r.path} className={({isActive}) => getLinkStyles(isActive)}
                                                    to={cleanupPath(r.path)}
                                                    aria-label="link">
                                        <div className={styles.navLinkIcon}>{r.menuIcon}</div>
                                        <Typography className={styles.navLinkText}>{t(r.i8nLabel)}</Typography>
                                    </NavLink>
                                }
                                return <></>
                            }
                        )
                    }
                </div>
                <div className={classNames(styles.sidenavContent, styles.bottom)}>
                    <NavLink className={({isActive}) => getLinkStyles(isActive)}
                             to={"mailto:acap-feedback@vhv.de?subject=[ACAP%20Feedback]:"}
                             aria-label="link">
                        <div className={styles.navLinkIcon}><QuestionAnswerIcon/></div>
                        <Typography className={styles.navLinkText}>{t("navigation.feedback")}</Typography>
                    </NavLink>
                </div>
            </div>
        </div>
    </Drawer>
};
