import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationDE from "./de/DE.json";
import translationEN from "./en/UK.json";
import LanguageDetector from "i18next-browser-languagedetector";

export const i18nsetup = () => {
    return i18n.use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources: {
                en: {translation: translationEN},
                de: {translation: translationDE},
            },
            lng: localStorage.getItem("i18nextLng") || "de",
            interpolation: {escapeValue: false},
            fallbackLng: "de"
        });
};