import {Box, Button, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useEffect, useState} from "react";
import {useCommentService} from "shared/hooks/services/CommentService";
import {Comment, CreateCommentInput, ListCommentsQueryVariables} from "shared/graphql/API";
import {Editor} from "@tinymce/tinymce-react";
import {UserComment} from "./UserComment";
import {useUserDataService} from "../../../../shared/hooks/services/useUserDataService";

export interface CommentScope {
    articleId?: string
    questionId?: string
}

export interface CommentsWidgetState {
    title: string,
    scope?: CommentScope,
}

export interface CommentsWidgetProps extends CommentsWidgetState {
    handleViewChange: (view: string) => void,
}

export const CommentsWidget = (props: CommentsWidgetProps) => {
    const {getFullName} = useUserDataService();
    const {createComment, listComments, deleteComment, updateComment} = useCommentService();
    const [comments, setComments] = useState<Array<Comment>>([]);
    const [value, setValue] = useState("");
    const [currentComment, setCurrentComment] = useState<Comment>();

    useEffect(() => {
        let filter: ListCommentsQueryVariables = {
            filter: {
                or: [
                    {
                        articleId: {eq: props.scope?.articleId || ""},
                    },
                    {
                        questionId: {eq: props.scope?.questionId || ""}
                    }
                ],
            }
        };
        listComments(filter).then(c => setComments(c));
    }, [listComments, props.scope?.articleId, props.scope?.questionId]);

    const handleSend = () => {
        if (currentComment) {
            updateComment({
                id: currentComment.id,
                content: value,
            })
                .then(c => setComments((p) => p.map(e => e.id === c.id ? c : e)))
                .finally(() => {
                    setCurrentComment(undefined);
                    setValue("");
                });
        } else {
            const input: CreateCommentInput = {
                content: value,
                articleId: props.scope?.articleId,
                questionId: props.scope?.questionId,
                ownerName: getFullName()
            }
            createComment(input)
                .then(c => setComments((p) => [...p, c]))
                .finally(() => {
                    setCurrentComment(undefined);
                    setValue("");
                });
        }
    }

    const handleDelete = (comment: Comment) => {
        deleteComment({
            id: comment.id,
        }).then(c => setComments((p) => p.filter(e => e.id !== c.id)));
    }

    const handleEdit = (comment: Comment) => {
        setCurrentComment(comment)
        setValue(comment.content)
    }

    return <>
        <CardHeader
            title={props.title}
            action={
                <IconButton onClick={() => props.handleViewChange("")} aria-label="settings">
                    <CloseIcon/>
                </IconButton>
            }/>
        <Divider></Divider>
        <CardContent sx={{padding: "1rem", paddingRight: 0, flexGrow: "1"}}>
            <List sx={{overflow: "auto", maxHeight: "60vh"}}>
                {comments.map(c => <ListItem key={c.id} sx={{width: "100%", p: ".1rem", paddingRight: "1rem"}}>
                        <UserComment onDelete={() => handleDelete(c)} onEdit={() => handleEdit(c)} comment={c}/>
                    </ListItem>
                )}
            </List>
        </CardContent>
        <Box sx={{padding: "1rem", paddingRight: "0.5rem"}}>
            <Editor value={value}
                    onEditorChange={(newValue, editor) => setValue(newValue)}
                    init={{
                        menubar: false,
                        content_css: "/assets/css/article-styles.css",
                        plugins: [
                            'advlist', 'emoticons', 'autolink', 'lists', 'link', 'anchor', 'insertdatetime', 'table'
                        ],
                        toolbar: 'styles bold italic forecolor emoticons bullist numlist | outdent indent | ' +
                            'alignleft aligncenter alignright alignjustify | table | removeformat',
                        max_height: 250,
                        min_height: 200,
                        style_formats: [
                            {title: 'Title', block: 'h4'},
                            {title: 'Subtitle', block: 'h6'},
                            {title: 'Standard', block: 'p'},
                            {title: 'Preformatted', block: 'pre'},
                        ],
                        branding: false,
                        visualblocks_default_state: true,
                        end_container_on_empty_block: true
                    }}
            />
        </Box>
        <CardActions
            disableSpacing
            sx={{
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                p: "0.5rem",
            }}>
            <Button variant={"contained"} onClick={() => handleSend()}>
                Senden
            </Button>
        </CardActions>
    </>
}
