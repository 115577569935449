import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {Client, generateClient} from "@aws-amplify/api";
import {AuthError, AuthSession, AuthUser, fetchAuthSession, signInWithRedirect} from "@aws-amplify/auth";

type AuthContextType = {
    user: AuthUser;
    session: AuthSession;
    client: Client;
    token: string;
}

export const UserContext = createContext<AuthContextType | null>(null);

const provider = {
    custom: "Azure-AD",
}

const HR_MINUTE_MS = 59 * 60 * 1000;

export const UserContextProvider = (props: any) => {
    const children: ReactNode = props.children;
    const [session, setSession] = useState<AuthSession | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [client, setClient] = useState<Client<never> | null>(null);

    useEffect(() => {
        signInWithRedirect({provider})
            .catch(e => {
                if (e instanceof AuthError) {
                    console.debug(e)
                }
            });
    }, []);

    useEffect(() => {
        const authUser = () => {
            fetchAuthSession()
                .then(s => {
                    setSession(s);
                    setToken(s.tokens?.idToken?.toString());
                    setClient(generateClient({authToken: s.tokens?.idToken?.toString()}));
                })
                .finally(() => {
                    setTimeout(authUser, HR_MINUTE_MS)
                });
        }
        authUser();
    }, []);

    return (
        <UserContext.Provider value={{session: session, client: client, token: token} as any}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (context === null) {
        throw new Error("useUserContext was used outside of its Provider");
    }
    return context;
}
