import styles from "./news-page.module.scss";
import {Masonry} from "@mui/lab";
import {NewsCard} from "../../components/news/NewsCard";
import React, {useEffect, useState} from "react";
import {NewsEditor} from "../../components/news/editor/NewsEditor";
import {useArticleService} from "shared/hooks/services/ArticleService";
import {Article} from "shared/graphql/API";
import {NewsWidgetCardSkeleton} from "../../components/news/NewsCardSkeleton";
import PushPinIcon from "@mui/icons-material/PushPin";
import {useTranslation} from "react-i18next";
import {Sidebar} from "../../components/sidebar/Sidebar";
import {useUserDataService} from "../../shared/hooks/services/useUserDataService";

export const NewsPage = () => {
    const {isAdmin} = useUserDataService();
    const {searchArticles, listArticles} = useArticleService();
    const [articles, setArticles] = useState<Array<Article>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const {t} = useTranslation();

    useEffect(() => {
        Sidebar.updateInfo({title: "News Page", content: t("content.homepage.welcomeSection.subtitle")});
    }, [t])

    useEffect(() => {
        listArticles().then(a => setArticles(a));
        // searchArticles({
        //     sort: [
        //         {field: SearchableArticleSortableFields.createdAt, direction: SearchableSortDirection.desc}
        //     ],
        //     limit: 25
        // })
        //     .then(a => setArticles(a))
        //     .finally(() => setLoading(false));
    }, [searchArticles]);

    const renderNewsMasonry = () => {
        if (loading) {
            return Array.from({length: 5}).map((e, i) => <NewsWidgetCardSkeleton key={`news-skeleton-${i}`}/>);
        } else {
            return articles.filter(a => {
                if (!a.active) {
                    return isAdmin();
                } else {
                    return true;
                }
            })
                .map((a) =>
                    <NewsCard key={a.id}
                              article={a}
                              to={`/news/${a.id}`}
                              action={
                                  <>
                                      {isAdmin() && a.pinned && <PushPinIcon color={"secondary"}/>}
                                  </>
                              }
                    />
                )
        }
    }

    return <div className={styles.newsMasonryContainer}>
        <Masonry columns={3} spacing={2} sequential>
            {renderNewsMasonry()}
        </Masonry>
        <NewsEditor/>
    </div>
}
