import {useCallback} from "react";
import {
    Article,
    CreateArticleInput,
    CreateArticleMutation,
    DeleteArticleInput,
    DeleteArticleMutation,
    GetArticleQuery, GetArticleQueryVariables,
    ListArticlesQuery,
    ListAuditsQueryVariables,
    UpdateArticleInput,
    UpdateArticleMutation
} from "shared/graphql/API";
import {GraphQLQuery} from "@aws-amplify/api";
import {getArticle as getArticleOperation, listArticles as listArticlesOperation} from "../../graphql/queries";
import {createArticle as createArticleOperation, deleteArticle as deleteArticleOperation, updateArticle as updateArticleOperation} from "../../graphql/mutations";
import {useUserContext} from "shared/context/UserContext";

export const useArticleService = () => {
    const {client} = useUserContext();

    const searchArticles = useCallback(async (variables: ListAuditsQueryVariables): Promise<Array<Article>> => {
        const {data} = await client.graphql<GraphQLQuery<ListArticlesQuery>>({
            query: listArticlesOperation,
            variables: variables,
        });
        return data?.listArticles?.items as Array<Article>;
    }, [client]);

    const createArticle = useCallback(async (input: CreateArticleInput): Promise<Article> => {
        const {data} = await client.graphql<GraphQLQuery<CreateArticleMutation>>({
            query: createArticleOperation,
            variables: {input: input},
        });
        return data?.createArticle as Article;
    }, [client]);

    const listArticles = useCallback(async (variables: ListAuditsQueryVariables = {}): Promise<Array<Article>> => {
        const {data} = await client.graphql<GraphQLQuery<ListArticlesQuery>>({
            query: listArticlesOperation,
            variables: variables,
        });
        return data?.listArticles?.items as Array<Article>;
    }, [client])

    const getArticle = useCallback(async (input: GetArticleQueryVariables): Promise<Article> => {
        const {data} = await client.graphql<GraphQLQuery<GetArticleQuery>>({
            query: getArticleOperation,
            variables: input,
        });
        return data?.getArticle as Article;
    }, [client]);

    const updateArticle = useCallback(async (input: UpdateArticleInput): Promise<Article> => {
        const {data} = await client.graphql<GraphQLQuery<UpdateArticleMutation>>({
            query: updateArticleOperation,
            variables: {input: input},
        });
        return data?.updateArticle as Article;
    }, [client]);

    const deleteArticle = useCallback(async (input: DeleteArticleInput): Promise<Article> => {
        const response = await client.graphql<GraphQLQuery<DeleteArticleMutation>>({
            query: deleteArticleOperation,
            variables: {input: input},
        });
        return response.data?.deleteArticle as Article
    }, [client]);

    return {createArticle, updateArticle, listArticles, searchArticles, getArticle, deleteArticle};
}
