import SvgIcon from '@mui/material/SvgIcon';

type BritainFlagProps = {
    [x: string]: any;
}

export const BritainFlagIcon = (props: BritainFlagProps) => {
    return <SvgIcon {...props}>
        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" fill={"currentColor"}>
                <path id="path4295" d="M0,25.6h32V6.4H0v19.2Z" fill="#fff" strokeWidth="0"/>
                <path id="path4297" d="M21.43,13.34l10.57-6.09v-.85h-.73l-12.06,6.94h2.21Z" fill="#bb1e36" strokeWidth="0"/>
                <path id="path4299" d="M20.93,19.04l11.07,6.37v-1.27l-8.86-5.1h-2.21Z" fill="#bb1e36" strokeWidth="0"/>
                <path id="path4301" d="M0,7.86l9.47,5.48h2.21L0,6.59v1.28Z" fill="#bb1e36" strokeWidth="0"/>
                <path id="path4303" d="M11.18,19.04L0,25.49v.11h2.02l11.38-6.56h-2.21Z" fill="#bb1e36" strokeWidth="0"/>
                <path id="path4305" d="M29.81,6.4h-11.2v6.46l11.2-6.46Z" fill="#29337a" strokeWidth="0"/>
                <path id="path4307" d="M13.66,6.4H2.5l11.16,6.46v-6.46Z" fill="#29337a" strokeWidth="0"/>
                <path id="path4309" d="M32,13.34v-4.4l-7.6,4.4h7.6Z" fill="#29337a" strokeWidth="0"/>
                <path id="path4311" d="M32,23.4v-4.36h-7.6l7.6,4.36Z" fill="#29337a" strokeWidth="0"/>
                <path id="path4313" d="M3.13,25.6h10.53v-6.07l-10.53,6.07Z" fill="#29337a" strokeWidth="0"/>
                <path id="path4315" d="M18.61,25.6h10.56l-10.56-6.07v6.07Z" fill="#29337a" strokeWidth="0"/>
                <path id="path4317" d="M0,19.04v4.55l7.86-4.55H0Z" fill="#29337a" strokeWidth="0"/>
                <path id="path4319" d="M0,13.34h7.86L0,8.78v4.56Z" fill="#29337a" strokeWidth="0"/>
                <path id="path4321" d="M14.65,6.4v8.08H0v3.42h14.65v7.7h2.97v-7.7h14.38v-3.42h-14.38V6.4h-2.97Z" fill="#bb1e36" strokeWidth="0"/>
            </svg>
    </SvgIcon>
}


