import React from 'react';
import "./styles/styles.scss"
import "./styles/article-styles.css"

import {i18nsetup} from "./locale/i18nConfiguration";
import dayjs from "dayjs";

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime';
import i18next from "i18next";
import 'dayjs/locale/de';
import "@aws-amplify/auth/enable-oauth-listener";
import {UserContextProvider} from "../shared/context/UserContext";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ThemeColorModeContextProvider, ThemeKey} from "@vhv/react-mui-theme/dist";
import {AppRouter} from "./AppRouter";
import {PortalLayout} from "./layouts/portal-layout/PortalLayout";
import {Notification} from "../shared/helpers/notification/Notification";

i18nsetup();
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.locale(i18next.resolvedLanguage);

function App() {
    return <UserContextProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeColorModeContextProvider themeKey={ThemeKey.VHV_GROUP}>
                <AppRouter layout={<PortalLayout/>}/>
                <Notification debug/>
            </ThemeColorModeContextProvider>
        </LocalizationProvider>
    </UserContextProvider>
}

export default App;
