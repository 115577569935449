import React, {useEffect, useState} from "react";
import {Initiative} from "shared/graphql/API";
import {Notification} from "shared/helpers/notification/Notification";
import styles from "app/styles/styles.module.scss";
import {useInitiativeService} from "shared/hooks/services/InitiativeService";
import {Card, CardContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Sidebar} from "../../components/sidebar/Sidebar";

export const CentralRegisterPage = () => {
    const {t} = useTranslation();
    const {listInitiatives} = useInitiativeService();
    const [initiatives, setInitiatives] = useState<Array<Initiative>>([]);

    useEffect(() => {
        Sidebar.updateInfo({title: "Central Register Page", content: t("content.homepage.welcomeSection.subtitle")});
    }, [t])

    useEffect(() => {
        listInitiatives()
            .then(c => setInitiatives(c))
            .catch((e) => Notification.error("Could not retrieve initiatives information.", e));
    }, [listInitiatives])

    const columns: GridColDef<Initiative>[] = [
        {
            field: 'id',
            headerName: t("content.common.id"),
            width: 300,
        },
        {
            field: 'name',
            headerName: t("content.common.name"),
            width: 300,
        },
        {
            field: 'description',
            headerName: t("content.common.description"),
            width: 300,
        },
        {
            field: 'projectOwner',
            headerName: t("content.common.projectOwner"),
            width: 250,
            description: ""
        },
        {
            field: 'projectResponsible',
            headerName: t("content.common.riscOfficer"),
            width: 250,
        },
        {
            field: 'riscOfficer',
            headerName: t("content.common.projectResponsible"),
            width: 250,
        },
        {
            field: 'serviceType',
            headerName: t("content.initiative.serviceType.title"),
            width: 250,
        },
        {
            field: 'cloudProvider',
            headerName: t("content.initiative.cloudProvider.title"),
            width: 250,
        },
        {
            field: 'status',
            headerName: t("content.common.status"),
            width: 160,
        },
        {
            field: 'details',
            headerName: "Details",
            width: 160,
        },
    ];

    const renderRegister = () => {
        return <DataGrid
            rows={initiatives}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 25,
                    },
                },
            }}
            pageSizeOptions={[25]}
            density={"compact"}
            checkboxSelection
            disableRowSelectionOnClick
        />
    }

    return <Card className={styles.card}>
        <CardContent>
            {renderRegister()}
        </CardContent>
    </Card>
}
