import React, {ChangeEvent, useEffect, useState} from "react";

import {AuditPhase, CloudEnvironment, CloudProvider, CloudServiceType, CreateInitiativeInput, Initiative, InitiativeStatus, UpdateInitiativeInput} from "shared/graphql/API";
import {Notification} from "shared/helpers/notification/Notification";
import {copyToClipboard, isNotBlank, omitGQLTypename} from "shared/helpers/CommonUtils";
import styles from "./initiative-card.module.scss";
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    PopoverActions,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useInitiativeService} from "shared/hooks/services/InitiativeService";
import {AuditPhaseStepper} from "components/audit/audit-stepper/AuditPhaseStepper";
import {useInitiativeData} from "shared/context/InitiativeContext";
import {useTranslation} from "react-i18next";
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from "@mui/icons-material/Info";
import {Sidebar} from "components/sidebar/Sidebar";
import {GenericStatus} from "components/status/GenericStatus";
import {useUserDataService} from "shared/hooks/services/useUserDataService";
import {UserSearch} from "components/search/UserSearch";
import {EntraIDUser} from "shared/graphql";

type InitiativeCardProps = {
    onCreate?: Function,
}


function InitiativeCard({onCreate}: InitiativeCardProps) {
    const {currentInitiative, setInitiative} = useInitiativeData();
    const {t} = useTranslation();
    const {getUsername, getFullName} = useUserDataService();
    const {updateInitiative, createInitiativeWithTemplateAudits} = useInitiativeService();
    const [initiativeId, setInitiativeId] = useState<string>();
    const [initiativeKey, setInitiativeKey] = useState<string>("");
    const [initiativeKeyMessage, setInitiativeKeyMessage] = useState<string>("");

    const [initiativeName, setInitiativeName] = useState<string>("");
    const [initiativeDescription, setInitiativeDescription] = useState<string>("");
    const [initiativePhase, setInitiativePhase] = useState<AuditPhase>(AuditPhase.basic);
    const [initiativeStatus, setInitiativeStatus] = useState<InitiativeStatus>(InitiativeStatus.open);
    const [riscOfficer, setRiscOfficer] = useState<EntraIDUser | null>();
    const [projectResponsible, setProjectResponsible] = useState<EntraIDUser | null>();
    const [projectOwner, setProjectOwner] = useState<EntraIDUser | null>();
    const [costUnit, setCostUnit] = useState<string>("");
    const [serviceType, setServiceType] = useState<CloudServiceType | string>("");
    const [cloudProvider, setCloudProvider] = useState<CloudProvider | string>("");
    const [cloudEnvironments, setCloudEnvironments] = useState<Array<CloudEnvironment>>([]);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const popoverActions = React.useRef<PopoverActions>();

    useEffect(() => {
        if (currentInitiative) {
            setInitiativeId(currentInitiative.id);
            setInitiativeKey(currentInitiative.key);
            setInitiativeName(currentInitiative.name);
            setInitiativeDescription(currentInitiative.description);
            setRiscOfficer(currentInitiative.riscOfficer!);
            setProjectResponsible(currentInitiative.projectResponsible!);
            setProjectOwner(currentInitiative.projectOwner!);
            setCostUnit(currentInitiative.costUnit!);
            setInitiativePhase(currentInitiative.phase!);
            setInitiativeStatus(currentInitiative.status);
            setServiceType((prev) => currentInitiative.serviceType ? currentInitiative.serviceType : prev);
            setCloudProvider((prev) => currentInitiative.cloudProvider ? currentInitiative.cloudProvider : prev);
            setCloudEnvironments((prev) => currentInitiative.cloudEnvironments ? currentInitiative.cloudEnvironments : prev);
            setDisabled(currentInitiative.owner !== getUsername() || currentInitiative.status === InitiativeStatus.inReview);
        }
    }, [currentInitiative, getUsername])


    const getInitiativeData = () => {
        if (validateInput())
            return {
                id: initiativeId,
                key: initiativeKey,
                name: initiativeName,
                description: initiativeDescription,
                riscOfficer: omitGQLTypename<EntraIDUser>(riscOfficer),
                projectResponsible: omitGQLTypename<EntraIDUser>(projectResponsible),
                projectOwner: omitGQLTypename<EntraIDUser>(projectOwner),
                phase: initiativePhase,
                status: initiativeStatus,
                serviceType,
                cloudProvider,
                cloudEnvironments,
                costUnit,
                lastEditor: getFullName(),
            }
        Notification.error("Please insert at least a name and a description.")
        return null;
    }
    const validateInput = () => {
        return isNotBlank(initiativeName) && isNotBlank(initiativeDescription) && isNotBlank(cloudProvider) && isNotBlank(serviceType);
    }

    const createNewInitiative = () => {
        setLoading(true);
        let initiativeData = getInitiativeData() as CreateInitiativeInput;
        initiativeData.ownerName = getFullName();
        createInitiativeWithTemplateAudits(initiativeData)
            .then((c: Initiative) => {
                setInitiative(c);
                onCreate && onCreate(c);
            })
            .then(() => Notification.success("Initiative created."))
            .catch((e) => {
                if (e.errors && e.errors[0].message === "Key is already in use.") {
                    setInitiativeKeyMessage(t("content.initiative.key.helperText.inUse"));
                }
                Notification.error("Initiative could not be created", e)
            })
            .finally(() => setLoading(false));
    }

    const updateCurrentInitiative = () => {
        updateInitiative(getInitiativeData() as UpdateInitiativeInput)
            .then((c: Initiative) => setInitiative(c))
            .then(() => Notification.success("Initiative updated."))
            .catch(() => Notification.error("Initiative could not be created"));
    }

    const renderInitiativeActions = () => {
        if (initiativeId && !disabled && initiativeStatus !== InitiativeStatus.inReview) {
            return <Tooltip title={t("action.update")}>
                <IconButton onClick={updateCurrentInitiative} color={"primary"}>
                    <SaveIcon/>
                </IconButton>
            </Tooltip>
        }
        if (!initiativeId) {
            return <Tooltip title={t("action.create")}>
                <IconButton onClick={createNewInitiative} color={"primary"}>
                    <SaveIcon/>
                </IconButton>
            </Tooltip>
        }
    }

    const getStatusColor = () => {
        switch (initiativeStatus) {
            case InitiativeStatus.open:
                return "default";
            case InitiativeStatus.inProgress:
                return "secondary";
            case InitiativeStatus.inReview:
                return "warning";
            case InitiativeStatus.cancelled:
                return "error";
            default:
                return "success";
        }
    }

    const handleInitiativeKeyValidation = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const reg = new RegExp("^[a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9]+$");
        const input = event.target.value.substring(0, 20);
        if (event.target.value.length < 2 || (!reg.test(input))) {
            setInitiativeKeyMessage(t("content.initiative.key.helperText.wrongFormat"));
        } else {
            setInitiativeKeyMessage("");
        }
        if (event.target.value.length <= 20) {
            setInitiativeKey(event.target.value);
        }
    };

    const handleCloudEnvironmentChange = (event: SelectChangeEvent<CloudEnvironment[]>) => {
        const {
            target: {value},
        } = event;
        setCloudEnvironments(
            typeof value === "string" ? value.split(',').map(v => v as CloudEnvironment) : value
        )
    }

    function handleCloudProviderChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        if (event.target.value !== CloudProvider.aws) {
            setCloudEnvironments([]);
        }
        setCloudProvider(event.target.value as CloudProvider);
    }

    const renderContent = () => {
        if (loading) {
            return <CircularProgress/>
        }
        return <Grid container spacing={2}>
            <Grid item xs={6}>
                <Grid container spacing={1} rowSpacing={2} columns={24}>
                    <Grid item xs={12}>
                        <TextField label={t("content.common.name")}
                                   size="small"
                                   value={initiativeName}
                                   onChange={e => setInitiativeName(e.target.value)}
                                   required
                                   InputProps={{
                                       readOnly: disabled
                                   }}
                                   fullWidth
                                   error={initiativeName?.trim().length === 0}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <TextField label={t("content.initiative.key.title")}
                                   size="small"
                                   value={initiativeKey}
                                   onChange={handleInitiativeKeyValidation}
                                   required
                                   color={initiativeId ? "secondary" : "primary"}
                                   InputProps={{
                                       readOnly: Boolean(initiativeId),
                                       endAdornment:
                                           <InputAdornment position="end">
                                               <IconButton onClick={() => copyToClipboard(initiativeKey, "Initiative key copied to clipboard.")} edge="end">
                                                   <ContentCopyIcon/>
                                               </IconButton>
                                           </InputAdornment>

                                   }}
                                   fullWidth
                                   error={initiativeKey?.trim().length === 0 || Boolean(initiativeKeyMessage)}
                                   helperText={initiativeKeyMessage}
                        />
                    </Grid>
                    <Grid item xs={1} classes={{item: styles.noLeftSpacing}}>
                        <IconButton onClick={() => Sidebar.showInfo({
                                title: t("content.initiative.key.title"),
                                content: t("content.initiative.key.tooltip", {"joinArrays": ""})
                            }
                        )}>
                            <InfoIcon color={"secondary"}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-ID">{t("content.common.id")}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-ID"
                                value={initiativeId || "---"}
                                label={t("content.common.id")}
                                readOnly
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => copyToClipboard(initiativeId, "Initiative id copied to clipboard.")} edge="end">
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={24}>
                        <TextField label={t("content.common.description")}
                                   size="small"
                                   value={initiativeDescription}
                                   onChange={e => setInitiativeDescription(e.target.value)}
                                   required
                                   InputProps={{
                                       readOnly: disabled,
                                   }}
                                   fullWidth
                                   multiline
                                   rows={6}
                                   error={initiativeDescription?.trim().length === 0}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Grid container columns={1} spacing={1} rowSpacing={2}>
                    <Grid item xs={1}>
                        <UserSearch label={t("content.common.projectOwner")}
                                    value={projectOwner}
                                    readOnly={disabled}
                                    onChange={user => setProjectOwner(user)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <UserSearch label={t("content.common.projectResponsible")}
                                    value={projectResponsible}
                                    readOnly={disabled}
                                    onChange={user => setProjectResponsible(user)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <UserSearch label={t("content.common.riscOfficer")}
                                    value={riscOfficer}
                                    readOnly={disabled}
                                    onChange={user => setRiscOfficer(user)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField label={t("content.common.costID")}
                                   value={costUnit}
                                   onChange={e => setCostUnit(e.target.value)}
                                   InputProps={{
                                       readOnly: disabled,
                                   }}
                                   fullWidth
                                   size="small"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Grid container columns={6} spacing={1} rowSpacing={2}>
                    <Grid item xs={5}>
                        <TextField className={styles.formControl}
                                   label={t("content.initiative.serviceType.title")}
                                   size="small"
                                   select
                                   value={serviceType}
                                   onChange={(e) => setServiceType(e.target.value as CloudServiceType)}
                                   fullWidth
                                   required
                                   InputProps={{
                                       readOnly: disabled
                                   }}
                                   error={serviceType?.trim().length === 0}
                        >
                            {Object.values(CloudServiceType).map(v => <MenuItem key={`cloud-service-${v}`} value={v}>{t(`content.initiative.serviceType.values.${v}`)}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={1} classes={{item: styles.noLeftSpacing}}>
                        <IconButton onClick={() => Sidebar.showInfo({
                                title: t("content.initiative.serviceType.title"),
                                content: t("content.initiative.serviceType.tooltip", {"joinArrays": "<br><br>"})
                            }
                        )}>
                            <InfoIcon color={"secondary"}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField className={styles.formControl}
                                   label={t("content.initiative.cloudProvider.title")}
                                   size="small"
                                   select
                                   value={cloudProvider}
                                   onChange={handleCloudProviderChange}
                                   fullWidth
                                   required
                                   InputProps={{
                                       readOnly: disabled
                                   }}
                                   error={cloudProvider?.trim().length === 0}
                        >
                            {Object.keys(CloudProvider).map(v => <MenuItem key={`cloud-provider-${v}`} value={v}>{t(`content.initiative.cloudProvider.values.${v}`)}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth size={"small"} disabled={cloudProvider !== CloudProvider.aws}>
                            <InputLabel id="initiative-cloud-env-label">{t("content.initiative.cloudEnvironment.title")}</InputLabel>
                            <Select
                                labelId="initiative-cloud-env-label"
                                multiple
                                value={cloudEnvironments}
                                onChange={handleCloudEnvironmentChange}
                                input={<OutlinedInput label={t("content.initiative.cloudEnvironment.title")}/>}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{
                                    action: popoverActions
                                }}
                            >
                                {Object.keys(CloudEnvironment).map(v =>
                                    <MenuItem key={`cloud-env-${v}`} value={v}>
                                        <Checkbox checked={cloudEnvironments.includes(v as CloudEnvironment)}/>
                                        <ListItemText>
                                            {t(`content.initiative.cloudEnvironment.values.${v}`)}
                                        </ListItemText>
                                    </MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} classes={{item: styles.noLeftSpacing}}>
                        <IconButton onClick={() => Sidebar.showInfo({
                            title: t(`content.initiative.cloudEnvironment.title`),
                            content: t("content.initiative.cloudEnvironment.description", {"joinArrays": "<br><br>"})
                        })}>
                            <InfoIcon color={"secondary"}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AuditPhaseStepper/>
            </Grid>
        </Grid>
    }

    const renderInitiativeCard = () => <Card className={styles.card}>
        <CardHeader
            title={
                <GenericStatus
                    status={initiativeStatus}
                    lastEditor={currentInitiative?.lastEditor}
                    statusColor={getStatusColor()}
                    ownerName={currentInitiative?.ownerName}
                    updatedAt={currentInitiative?.updatedAt}
                />
            }
            action={renderInitiativeActions()}
        />
        <CardContent className={styles.cardContainer}>
            {renderContent()}
        </CardContent>
    </Card>


    return renderInitiativeCard();
}

export default InitiativeCard
