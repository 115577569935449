import React, {useEffect, useState} from "react";
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import {AutocompletionEntry, useAuditAutocompletionData} from "../../../shared/context/AuditAutocompletionContext";
import {useAuditData} from "../../../shared/context/AuditEditorContext";
import {AuditPhase} from "shared/graphql/API";
import {useTranslation} from "react-i18next";
import {AnswerValue} from "shared/graphql";

type AnswerAuditReferenceProps = {
    answerValue: AnswerValue;
    onChange?: Function;
    onCreate?: Function;
    onDelete?: Function;
    className?: string;
}

function AnswerAuditReference(props: AnswerAuditReferenceProps) {
    const {answerValue, className} = props;
    const {onChange} = props;
    const {t} = useTranslation();
    const auditCompletionData = useAuditAutocompletionData();
    const audit = useAuditData();
    const [references, setReferences] = useState<Array<AutocompletionEntry>>([]);

    useEffect(() => {
        setReferences(auditCompletionData.filter(a => answerValue.auditReferences?.includes(a.id)));
    }, [answerValue.auditReferences, auditCompletionData]);

    const handleChange = (event: SelectChangeEvent<Array<string>>) => {
        const {
            target: {value},
        } = event;

        let selectedReferences;
        if (typeof value === 'string') {
            selectedReferences = auditCompletionData.filter(a => value.split(',').includes(a.id));
        } else {
            selectedReferences = auditCompletionData.filter(a => value.includes(a.id));
        }
        onChange && onChange(selectedReferences.map(r => r.id));
        setReferences(selectedReferences);
    };

    const renderAuditReferenceDropdown = () => {
        if (audit.phase !== AuditPhase.specific) {
            return <FormControl className={className}>
                <InputLabel id="audit-reference-checkbox-label">{t("content.common.associations")}</InputLabel>
                <Select
                    labelId="audit-reference-checkbox-label"
                    multiple
                    value={references.map(r => r.id)}
                    onChange={handleChange}
                    input={<OutlinedInput label="Verknüpfungen"/>}
                    renderValue={(selected) => selected.length === 1 ?
                        `${selected.length} ${t("content.common.association")}`
                        : `${selected.length} ${t("content.common.associations")}`
                    }
                >
                    {auditCompletionData
                        .filter(e => e.id !== audit.id)
                        .filter(e => audit.phase === AuditPhase.basic ? e.phase === AuditPhase.thematic : 1)
                        .filter(e => audit.phase === AuditPhase.thematic ? e.phase === AuditPhase.specific : 1)
                        .map((r) =>
                            <MenuItem key={audit.id + r.id} value={r.id}>
                                <Checkbox checked={references.map(s => s.id).includes(r.id)}/>
                                <ListItemText primary={r.name.length > 40 ? r.name.slice(0, 40) + "..." : r.name} secondary={r.id}/>
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        }
        return <></>
    }

    return renderAuditReferenceDropdown()
}

export default AnswerAuditReference;

