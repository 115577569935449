import {useCallback} from "react";
import {
    Comment,
    CreateCommentInput,
    CreateCommentMutation,
    DeleteCommentInput,
    DeleteCommentMutation,
    ListCommentsQuery,
    ListCommentsQueryVariables,
    UpdateCommentInput,
    UpdateCommentMutation
} from "shared/graphql/API";
import {GraphQLQuery} from "@aws-amplify/api";
import {listComments as listCommentsOperation} from "../../graphql/queries";
import {createComment as createCommentOperation, deleteComment as deleteCommentOperation, updateComment as updateCommentOperation} from "../../graphql/mutations";
import {useUserContext} from "shared/context/UserContext";

export const useCommentService = () => {
    const {client} = useUserContext();

    const listComments = useCallback(async (variables: ListCommentsQueryVariables = {}): Promise<Array<Comment>> => {
        const {data} = await client.graphql<GraphQLQuery<ListCommentsQuery>>({
            query: listCommentsOperation,
            variables: variables,
        });
        return data?.listComments?.items as Array<Comment>;
    }, [client])

    const createComment = useCallback(async (input: CreateCommentInput): Promise<Comment> => {
        const {data} = await client.graphql<GraphQLQuery<CreateCommentMutation>>({
            query: createCommentOperation,
            variables: {input: input},
        });
        return data?.createComment as Comment;
    }, [client]);

    const deleteComment = useCallback(async (input: DeleteCommentInput): Promise<Comment> => {
        const response = await client.graphql<GraphQLQuery<DeleteCommentMutation>>({
            query: deleteCommentOperation,
            variables: {input: input},
        });
        return response.data?.deleteComment as Comment
    }, [client]);

    const updateComment = useCallback(async (input: UpdateCommentInput): Promise<Comment> => {
        const {data} = await client.graphql<GraphQLQuery<UpdateCommentMutation>>({
            query: updateCommentOperation,
            variables: {input: input},
        });
        return data?.updateComment as Comment;
    }, [client]);


    return {createComment, listComments, deleteComment, updateComment};
}
