import * as React from "react";
import {Alert} from "@mui/material";
import {AlertColor} from "@mui/material/Alert";

import styles from "./notification.module.scss";
import _ from "lodash";

type NotificationProps = {
    className?: string;
    debug?: boolean;
}

type NotificationState = {
    alerts: {
        key: string;
        message: string;
        level: AlertColor;
    }[];
}

export class Notification extends React.Component<NotificationProps, NotificationState> {
    private static instance: Notification;

    public constructor(props: NotificationProps) {
        super(props);
        this.state = {
            alerts: []
        };
        Notification.instance = this;
    }

    public static success(message: string): void {
        Notification.instance.postAlert("success", message);
    }

    public static error(message: string, e?: any): void {
        Notification.instance.postAlert("error", message);
        if (Notification.instance.props.debug)
            console.log("Debug message", e);
    }

    public static warning(message: string): void {
        Notification.instance.postAlert("warning", message);
    }

    public static info(message: string): void {
        Notification.instance.postAlert("info", message);
    }

    public render(): React.ReactNode {
        return <div className={styles.stackedNotifications}>
            {this.state.alerts.map(({key, level, message}) => (
                <Alert key={key}
                       className={styles.notification}
                       variant="filled"
                       severity={level}
                       onClose={() => this.hideAlert(key)}
                >
                    {message}
                </Alert>))
            }
        </div>
    }

    private readonly hideAlert = (key: string) => {
        this.setState({alerts: [...this.state.alerts.filter(el => el.key !== key)]});
    }

    private readonly postAlert = (level: AlertColor, message: string) => {
        const alerts = this.state.alerts;
        const key = _.uniqueId();
        alerts.push({key, message: message, level});
        this.setState({alerts});
        if (level !== "error") {
            setTimeout(() => this.hideAlert(key), 5000);
        }
    }
}
