import React, {useState} from "react";

import _ from 'lodash';

import styles from "./answer.module.scss"
import {Answer, AnswerInput, AnswerType, AnswerValue, AnswerValueInput} from "shared/graphql/API";
import {Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, TextField} from "@mui/material";
import {useInitiativeData} from "shared/context/InitiativeContext";

interface AuditAnswerProps {
    answer: Answer;
    onChange?: (arg0: AnswerInput) => void
}

function AuditAnswer(props: AuditAnswerProps) {
    const {answer, onChange} = props;
    const [AnswerValues, setAnswerValues] = useState<Array<AnswerValue>>(answer?.values);
    const [timer, setTimer] = useState<number>();
    const {setUpdating} = useInitiativeData();

    const performDataUpdate = (currentValues: Array<AnswerValue>) => {
        if (onChange) {
            setUpdating(true);
            if (timer)
                window.clearTimeout(timer);
            const newTimer = window.setTimeout(() => {
                onChange({
                    values: currentValues.map(s => _.omit(s, ["__typename"]) as AnswerValueInput),
                    type: answer.type,
                });
            }, 1000);
            setTimer(newTimer);
        }
    }

    const updateAnswerValue = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, checkbox = false) => {
        let currentValue: Array<AnswerValue>;
        if (checkbox) {
            currentValue = AnswerValues.map(a => {
                if (a.value === event.target.value) {
                    a.selected = (event.target as HTMLInputElement).checked;
                }
                return a;
            })
        } else {
            currentValue = AnswerValues.map(a => {
                a.selected = a.value === event.target.value;
                return a;
            })
        }
        setAnswerValues(currentValue);
        performDataUpdate(currentValue);
    }

    const renderAnswer = () => {
        switch (answer?.type) {
            case AnswerType.text:
                return <div className={styles.answerSection}>
                    <TextField multiline rows={3} value={AnswerValues[0]?.value} onChange={e => updateAnswerValue(e)}></TextField>
                </div>
            case AnswerType.check:
                return <FormGroup>
                    {AnswerValues
                        .filter((a): a is AnswerValue => a != null)
                        .map((a: AnswerValue) =>
                            <FormControlLabel key={a.value}
                                              className={styles.checkboxSelection}
                                              control={
                                                  <Checkbox checked={a.selected}
                                                            value={a.value}
                                                            onChange={e => updateAnswerValue(e, true)}/>
                                              }
                                              label={a.value}
                            />
                        )
                    }
                </FormGroup>
            case AnswerType.radio:
                return <RadioGroup name="radio-buttons-group" onChange={e => updateAnswerValue(e)}>
                    {AnswerValues
                        .filter((a): a is AnswerValue => a != null)
                        .map((a: AnswerValue) =>
                            <FormControlLabel key={a.value}
                                              className={styles.checkboxSelection}
                                              value={a.value}
                                              control={<Radio/>}
                                              label={a.value}
                                              checked={a.selected}
                            />
                        )
                    }
                </RadioGroup>
            default:
                return <div></div>
        }
    }

    return renderAnswer()

}

export default AuditAnswer;
