import React, {ReactNode} from "react";
import {InitiativePage} from "pages/initiative/InitiativePage";
import {HomePage} from "pages/home/HomePage";
import {NewsPage} from "pages/news/NewsPage";
import {ArticleView} from "components/news/article/ArticleView";
import {AuditCatalogPage} from "pages/audit-catalog/AuditCatalogPage";
import {CentralRegisterPage} from "pages/register/CentralRegisterPage";
import {ForbiddenPage} from "pages/forbidden/ForbiddenPage";
import {CreditsPage} from "pages/attribution-credits/CreditsPage";
import {NotFoundPage} from "pages/notfound/NotFoundPage";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HomeIcon from '@mui/icons-material/Home';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ArticleIcon from '@mui/icons-material/Article';
import {UserGroups} from "shared/helpers/UserGroups";
import {SearchPage} from "../pages/search/SearchPage";

export type NavigationRouteType = {
    element: ReactNode,
    path: string,
    i8nLabel?: string,
    menuIcon?: ReactNode,
    groups?: UserGroups[]
}

export const NavigationRoutes: NavigationRouteType[] = [
    {
        i8nLabel: "navigation.dashboardPage",
        element: <HomePage/>,
        path: "/",
        menuIcon: <HomeIcon/>
    },
    {
        i8nLabel: "navigation.initiativePage",
        element: <InitiativePage/>,
        path: "/initiatives/:id?",
        menuIcon: <LibraryBooksIcon/>,
        groups: [UserGroups.ADMIN],
    },
    {
        i8nLabel: "navigation.newsPage",
        element: <NewsPage/>,
        path: "/news",
        menuIcon: <ArticleIcon/>
    },
    {
        element: <ArticleView/>,
        path: "/news/:id",
    },
    {
        i8nLabel: "navigation.auditEditorPage",
        element: <AuditCatalogPage/>,
        path: "/audit-catalog",
        menuIcon: <EditCalendarIcon/>,
        groups: [UserGroups.ADMIN],
    },
    {
        i8nLabel: "navigation.registerPage",
        element: <CentralRegisterPage/>,
        path: "/central-register",
        menuIcon: <AutoAwesomeMotionIcon/>,
        groups: [UserGroups.ADMIN],
    },
    {
        element: <SearchPage/>,
        path: "/search",
    },
    {
        element: <ForbiddenPage/>,
        path: "/forbidden",
    },
    {
        element: <CreditsPage/>,
        path: "/attribution-credits",
    },
    {
        element: <NotFoundPage/>,
        path: "*",
    }
];
