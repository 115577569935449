/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getArticle = /* GraphQL */ `query GetArticle($id: ID!) {
  getArticle(id: $id) {
    active
    comments {
      items {
        article {
          active
          content
          createdAt
          id
          introduction
          pinned
          thumbnail
          title
          topics
          updatedAt
          __typename
        }
        articleId
        content
        createdAt
        id
        owner
        ownerName
        question {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        questionId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    content
    createdAt
    id
    introduction
    pinned
    thumbnail
    title
    topics
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArticleQueryVariables,
  APITypes.GetArticleQuery
>;
export const getAudit = /* GraphQL */ `query GetAudit($id: ID!) {
  getAudit(id: $id) {
    createdAt
    description
    editors
    id
    initiative {
      audits {
        items {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cloudEnvironments
      cloudProvider
      costUnit
      createdAt
      description
      editors
      id
      key
      lastEditor
      name
      owner
      ownerName
      phase
      projectOwner {
        department
        displayName
        id
        mail
        __typename
      }
      projectResponsible {
        department
        displayName
        id
        mail
        __typename
      }
      riscOfficer {
        department
        displayName
        id
        mail
        __typename
      }
      serviceType
      status
      updatedAt
      __typename
    }
    initiativeId
    isActive
    isTemplate
    lastEditor
    name
    originAuditId
    owner
    ownerName
    phase
    questions {
      items {
        answer {
          type
          __typename
        }
        audit {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        auditId
        comments {
          nextToken
          __typename
        }
        createdAt
        description
        editors
        favourite
        id
        owner
        question
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAuditQueryVariables, APITypes.GetAuditQuery>;
export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    article {
      active
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content
      createdAt
      id
      introduction
      pinned
      thumbnail
      title
      topics
      updatedAt
      __typename
    }
    articleId
    content
    createdAt
    id
    owner
    ownerName
    question {
      answer {
        type
        values {
          auditReferences
          selected
          value
          __typename
        }
        __typename
      }
      audit {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      auditId
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      description
      editors
      favourite
      id
      owner
      question
      updatedAt
      __typename
    }
    questionId
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const getInitiative = /* GraphQL */ `query GetInitiative($id: ID!) {
  getInitiative(id: $id) {
    audits {
      items {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    cloudEnvironments
    cloudProvider
    costUnit
    createdAt
    description
    editors
    id
    key
    lastEditor
    name
    owner
    ownerName
    phase
    projectOwner {
      department
      displayName
      id
      mail
      __typename
    }
    projectResponsible {
      department
      displayName
      id
      mail
      __typename
    }
    riscOfficer {
      department
      displayName
      id
      mail
      __typename
    }
    serviceType
    status
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInitiativeQueryVariables,
  APITypes.GetInitiativeQuery
>;
export const getQuestion = /* GraphQL */ `query GetQuestion($id: ID!) {
  getQuestion(id: $id) {
    answer {
      type
      values {
        auditReferences
        selected
        value
        __typename
      }
      __typename
    }
    audit {
      createdAt
      description
      editors
      id
      initiative {
        audits {
          nextToken
          __typename
        }
        cloudEnvironments
        cloudProvider
        costUnit
        createdAt
        description
        editors
        id
        key
        lastEditor
        name
        owner
        ownerName
        phase
        projectOwner {
          department
          displayName
          id
          mail
          __typename
        }
        projectResponsible {
          department
          displayName
          id
          mail
          __typename
        }
        riscOfficer {
          department
          displayName
          id
          mail
          __typename
        }
        serviceType
        status
        updatedAt
        __typename
      }
      initiativeId
      isActive
      isTemplate
      lastEditor
      name
      originAuditId
      owner
      ownerName
      phase
      questions {
        items {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    auditId
    comments {
      items {
        article {
          active
          content
          createdAt
          id
          introduction
          pinned
          thumbnail
          title
          topics
          updatedAt
          __typename
        }
        articleId
        content
        createdAt
        id
        owner
        ownerName
        question {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        questionId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    description
    editors
    favourite
    id
    owner
    question
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuestionQueryVariables,
  APITypes.GetQuestionQuery
>;
export const initiativesByKey = /* GraphQL */ `query InitiativesByKey(
  $filter: ModelInitiativeFilterInput
  $key: String!
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  initiativesByKey(
    filter: $filter
    key: $key
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      audits {
        items {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cloudEnvironments
      cloudProvider
      costUnit
      createdAt
      description
      editors
      id
      key
      lastEditor
      name
      owner
      ownerName
      phase
      projectOwner {
        department
        displayName
        id
        mail
        __typename
      }
      projectResponsible {
        department
        displayName
        id
        mail
        __typename
      }
      riscOfficer {
        department
        displayName
        id
        mail
        __typename
      }
      serviceType
      status
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InitiativesByKeyQueryVariables,
  APITypes.InitiativesByKeyQuery
>;
export const listArticles = /* GraphQL */ `query ListArticles(
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      active
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content
      createdAt
      id
      introduction
      pinned
      thumbnail
      title
      topics
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArticlesQueryVariables,
  APITypes.ListArticlesQuery
>;
export const listAuditByInitiativeId = /* GraphQL */ `query ListAuditByInitiativeId(
  $filter: ModelAuditFilterInput
  $initiativeId: ID!
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAuditByInitiativeId(
    filter: $filter
    initiativeId: $initiativeId
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      description
      editors
      id
      initiative {
        audits {
          nextToken
          __typename
        }
        cloudEnvironments
        cloudProvider
        costUnit
        createdAt
        description
        editors
        id
        key
        lastEditor
        name
        owner
        ownerName
        phase
        projectOwner {
          department
          displayName
          id
          mail
          __typename
        }
        projectResponsible {
          department
          displayName
          id
          mail
          __typename
        }
        riscOfficer {
          department
          displayName
          id
          mail
          __typename
        }
        serviceType
        status
        updatedAt
        __typename
      }
      initiativeId
      isActive
      isTemplate
      lastEditor
      name
      originAuditId
      owner
      ownerName
      phase
      questions {
        items {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAuditByInitiativeIdQueryVariables,
  APITypes.ListAuditByInitiativeIdQuery
>;
export const listAudits = /* GraphQL */ `query ListAudits(
  $filter: ModelAuditFilterInput
  $limit: Int
  $nextToken: String
) {
  listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      createdAt
      description
      editors
      id
      initiative {
        audits {
          nextToken
          __typename
        }
        cloudEnvironments
        cloudProvider
        costUnit
        createdAt
        description
        editors
        id
        key
        lastEditor
        name
        owner
        ownerName
        phase
        projectOwner {
          department
          displayName
          id
          mail
          __typename
        }
        projectResponsible {
          department
          displayName
          id
          mail
          __typename
        }
        riscOfficer {
          department
          displayName
          id
          mail
          __typename
        }
        serviceType
        status
        updatedAt
        __typename
      }
      initiativeId
      isActive
      isTemplate
      lastEditor
      name
      originAuditId
      owner
      ownerName
      phase
      questions {
        items {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAuditsQueryVariables,
  APITypes.ListAuditsQuery
>;
export const listCommentByQuestionId = /* GraphQL */ `query ListCommentByQuestionId(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
  $questionId: ID!
  $sortDirection: ModelSortDirection
) {
  listCommentByQuestionId(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    questionId: $questionId
    sortDirection: $sortDirection
  ) {
    items {
      article {
        active
        comments {
          nextToken
          __typename
        }
        content
        createdAt
        id
        introduction
        pinned
        thumbnail
        title
        topics
        updatedAt
        __typename
      }
      articleId
      content
      createdAt
      id
      owner
      ownerName
      question {
        answer {
          type
          __typename
        }
        audit {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        auditId
        comments {
          nextToken
          __typename
        }
        createdAt
        description
        editors
        favourite
        id
        owner
        question
        updatedAt
        __typename
      }
      questionId
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentByQuestionIdQueryVariables,
  APITypes.ListCommentByQuestionIdQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      article {
        active
        comments {
          nextToken
          __typename
        }
        content
        createdAt
        id
        introduction
        pinned
        thumbnail
        title
        topics
        updatedAt
        __typename
      }
      articleId
      content
      createdAt
      id
      owner
      ownerName
      question {
        answer {
          type
          __typename
        }
        audit {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        auditId
        comments {
          nextToken
          __typename
        }
        createdAt
        description
        editors
        favourite
        id
        owner
        question
        updatedAt
        __typename
      }
      questionId
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const listInitiatives = /* GraphQL */ `query ListInitiatives(
  $filter: ModelInitiativeFilterInput
  $limit: Int
  $nextToken: String
) {
  listInitiatives(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      audits {
        items {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cloudEnvironments
      cloudProvider
      costUnit
      createdAt
      description
      editors
      id
      key
      lastEditor
      name
      owner
      ownerName
      phase
      projectOwner {
        department
        displayName
        id
        mail
        __typename
      }
      projectResponsible {
        department
        displayName
        id
        mail
        __typename
      }
      riscOfficer {
        department
        displayName
        id
        mail
        __typename
      }
      serviceType
      status
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInitiativesQueryVariables,
  APITypes.ListInitiativesQuery
>;
export const listQuestionByAuditId = /* GraphQL */ `query ListQuestionByAuditId(
  $auditId: ID!
  $filter: ModelQuestionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listQuestionByAuditId(
    auditId: $auditId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      answer {
        type
        values {
          auditReferences
          selected
          value
          __typename
        }
        __typename
      }
      audit {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      auditId
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      description
      editors
      favourite
      id
      owner
      question
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuestionByAuditIdQueryVariables,
  APITypes.ListQuestionByAuditIdQuery
>;
export const listQuestions = /* GraphQL */ `query ListQuestions(
  $filter: ModelQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      answer {
        type
        values {
          auditReferences
          selected
          value
          __typename
        }
        __typename
      }
      audit {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      auditId
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      description
      editors
      favourite
      id
      owner
      question
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuestionsQueryVariables,
  APITypes.ListQuestionsQuery
>;
