import {createContext, useContext} from "react";
import {Audit} from "shared/graphql/API";

type AuditEditorContextType = {
    audit: Audit
}

const AuditEditorContext = createContext<AuditEditorContextType | null>(null);

const useAuditData = (): Audit => {
    const context = useContext(AuditEditorContext);
    if (context === null) {
        throw new Error("AuditEditorContext was used outside of its Provider");
    }
    return context.audit;
}

export {AuditEditorContext, useAuditData};
