import React, {useEffect, useState} from "react";
import parse from "html-react-parser";
import {Backdrop, Card, CardActionArea, CardContent, CardHeader, CircularProgress, Grid, List, ListItem, Typography} from "@mui/material";
import {Article, Initiative } from "shared/graphql/API";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useArticleService} from "../../shared/hooks/services/ArticleService";
import {useInitiativeService} from "../../shared/hooks/services/InitiativeService";
import {useTranslation} from "react-i18next";
import {GenericStatus} from "../../components/status/GenericStatus";
import {createSearchQuery, isNotBlank} from "../../shared/helpers/CommonUtils";
import _ from "lodash";


export const SearchPage = () => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [articles, setArticles] = useState<Array<Article>>([]);
    const [initiatives, setInitiatives] = useState<Array<Initiative>>([]);
    const {searchArticles} = useArticleService();
    const {searchInitiatives} = useInitiativeService();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const highlightTagOpen = '<span style="background-color: rgb(219, 233, 128)">';
    const highlightTagClosed = '</span>';

    // useEffect(() => {
    //     if (isNotBlank(searchParams.get("s"))) {
    //         // @ts-ignore: see isNotBlank function
    //         const text = searchParams.get("s").toLowerCase()
    //         if (searchParams.get("c") === "news") {
    //             setLoading(true);
    //             searchArticles({
    //                 filter: {
    //                     or: [
    //                         ...createSearchQuery(text, "title"),
    //                         ...createSearchQuery(text, "introduction"),
    //                         ...createSearchQuery(text, "content"),
    //                     ],
    //                     active: {eq: true}
    //                 },
    //                 sort: [
    //                     {field: SearchableArticleSortableFields.pinned, direction: SearchableSortDirection.desc},
    //                     {field: SearchableArticleSortableFields.createdAt, direction: SearchableSortDirection.desc},
    //                 ],
    //                 limit: 5
    //             })
    //                 .then(a => setArticles(a))
    //                 .then(() => setInitiatives([]))
    //                 .finally(() => setLoading(false));
    //         } else if (searchParams.get("c") === "initiative") {
    //             setLoading(true);
    //             searchInitiatives({
    //                 filter: {
    //                     or: [
    //                         ...createSearchQuery(text, "id"),
    //                         ...createSearchQuery(text, "name"),
    //                         ...createSearchQuery(text, "description"),
    //                         ...createSearchQuery(text, "projectOwner"),
    //                         ...createSearchQuery(text, "riscOfficer"),
    //                         ...createSearchQuery(text, "projectResponsible"),
    //                         ...createSearchQuery(text, "costUnit"),
    //                     ],
    //                 },
    //                 sort: [
    //                     {field: SearchableInitiativeSortableFields.createdAt, direction: SearchableSortDirection.desc},
    //                 ],
    //                 limit: 5
    //             })
    //                 .then(a => setInitiatives(a))
    //                 .then(() => setArticles([]))
    //                 .finally(() => setLoading(false));
    //         }
    //     }
    // }, [searchArticles, searchInitiatives, searchParams]);

    const highlightText = (text?: string | null) => {
        const searchText = searchParams.get("s");
        if (searchText && text) {
            _.uniq(searchParams.get("s")?.split(" ")).forEach(s => {
                const regExp = new RegExp(s, "gi");
                text = text?.replaceAll(regExp, (substring) => highlightTagOpen + substring + highlightTagClosed)
            });
            return parse(text);
        }
    }

    const renderInitiatives = () => {
        return initiatives?.map(c => <ListItem key={c.id}>
                <Card sx={{width: "100%", margin: "0 auto"}}>
                    <CardActionArea onClick={() => navigate(`/initiatives/${c.id}`, {relative: "route"})}>
                        {/*<CardHeader title={highlightText(c.name)} subheader={highlightText(c.id)}/>*/}
                        <CardHeader
                            title={
                                <GenericStatus
                                    status={c.status}
                                    lastEditor={c?.lastEditor}
                                    ownerName={c?.ownerName}
                                    updatedAt={c?.updatedAt}
                                />
                            }
                        />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography variant={"h6"}>{t("content.common.name")}</Typography>
                                    <Typography variant={"body2"}>{highlightText(c.name)}</Typography>
                                    <Typography variant={"body2"}>{highlightText(c.id)}</Typography>
                                    <Typography variant={"h6"}>{t("content.common.description")}</Typography>
                                    <Typography variant={"body2"}>{highlightText(c.description)}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={"h6"}>{t("content.common.riscOfficer")}</Typography>
                                    <Typography variant={"body2"}>{highlightText(c.riscOfficer?.displayName)}</Typography>
                                    <Typography variant={"h6"}>{t("content.common.projectOwner")}</Typography>
                                    <Typography variant={"body2"}>{highlightText(c.projectOwner?.displayName)}</Typography>
                                    <Typography variant={"h6"}>{t("content.common.projectResponsible")}</Typography>
                                    <Typography variant={"body2"}>{highlightText(c.projectResponsible?.displayName)}</Typography>
                                    <Typography variant={"h6"}>{t("content.common.costID")}</Typography>
                                    <Typography variant={"body2"}>{highlightText(c.costUnit)}</Typography>
                                </Grid>
                                <Grid item xs={4}>

                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </ListItem>
        )
    }

    const renderArticles = () => {
        return articles?.map(a => <ListItem key={a.id}>
                <Card sx={{width: "100%", margin: "0 auto"}}>
                    <CardActionArea onClick={() => navigate(`/news/${a.id}`, {relative: "route"})}>
                        <CardHeader title={highlightText(a.title)} subheader={highlightText(a.introduction)}/>
                        <CardContent>{highlightText(a.content)}</CardContent>
                    </CardActionArea>
                </Card>
            </ListItem>
        )
    }

    const renderSearchResult = () => {
        if (loading) {
            return <Backdrop open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        }
        return <List sx={{width: "50%", margin: "0 auto"}}>
            {renderInitiatives()}
            {renderArticles()}
            {!articles[0] && !initiatives[0] && <p>keine Treffer</p>}
        </List>
    }
    return renderSearchResult();
}
