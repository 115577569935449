import * as React from "react";
import {Box, Card, Drawer, IconButton, Slide} from "@mui/material";

import styles from "./sidebar.module.scss";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import InfoIcon from "@mui/icons-material/Info";
import CommentIcon from '@mui/icons-material/Comment';

import {InfoWidget, InfoWidgetState} from "./components/InfoWidget";
import {CommentsWidget, CommentsWidgetState} from "./components/comment/CommentsWidget";
import {ThemeColorModeContext} from "@vhv/react-mui-theme/dist/hooks/useThemeMode";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {LocaleSelect} from "../locale/LocaleSelect";

interface SidebarState {
    view: string | null,
    width: number,
    showSidebar: boolean,

    widget?: CommentsWidgetState | InfoWidgetState
}

const minSidbarWidth = 500;

export class Sidebar extends React.Component<{}, SidebarState> {
    private static instance: Sidebar;
    private readonly sidebarRef;

    public constructor(props: {}) {
        super(props);
        this.state = {
            view: null,
            width: minSidbarWidth,
            showSidebar: false,

        }
        Sidebar.instance = this;

        this.sidebarRef = React.createRef<HTMLDivElement>();
    }

    public static showComments(state: CommentsWidgetState) {
        Sidebar.updateComments(state, "comment", true);
    }

    public static showInfo(state: InfoWidgetState,) {
        Sidebar.updateInfo(state, "info", true);
    }

    public static updateInfo(state: InfoWidgetState, view: string | null = null, showSidebar = false): void {
        Sidebar.instance.setState({widget: state, view: view, showSidebar: showSidebar});
    }

    public static updateComments(state: CommentsWidgetState, view: string | null = null, showSidebar = false): void {
        Sidebar.instance.setState({widget: state, view: view, showSidebar: showSidebar});
    }

    private handleChange = (nextView: string) => {
        if (!nextView) {
            this.setState({showSidebar: false})
        } else {
            this.setState({showSidebar: true})
        }
        this.setState({view: nextView})
    };

    private rsMouseDownHandler(e: any) {
        if (this.sidebarRef.current) {
            const x = e.clientX;
            const sbWidth = window.getComputedStyle(this.sidebarRef.current).width;
            const initialWidth = parseInt(sbWidth, 10);
            const mouseMoveHandler = (e: any) => {
                const dx = x - e.clientX;
                const newWidth = initialWidth + dx;
                if (newWidth >= minSidbarWidth) {
                    this.setState({width: newWidth});
                } else {
                    this.setState({width: minSidbarWidth});
                }
            };
            const mouseUpHandler = () => {
                document.removeEventListener('mouseup', mouseUpHandler);
                document.removeEventListener('mousemove', mouseMoveHandler);
            };
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        }
    };

    private renderWidget() {
        if (this.state.view === "info") {
            return <InfoWidget {...this.state.widget as InfoWidgetState} handleViewChange={this.handleChange}/>
        } else if (this.state.view === "comment") {
            return <CommentsWidget {...this.state.widget as CommentsWidgetState} handleViewChange={this.handleChange}/>
        }
    }


    public render(): React.ReactNode {
        return <>
            <Slide in={this.state.showSidebar} direction={"up"} unmountOnExit>
                <Box className={styles.sideToolbarContent} style={{width: this.state.width}}>
                    <Card className={styles.sideToolbarContentContainer} style={{width: this.state.width}} ref={this.sidebarRef}>
                        <Box sx={{
                            position: 'absolute',
                            insetInlineStart: 0,
                            insetBlockStart: 0,
                            blockSize: "100%",
                            inlineSize: 18,
                            display: "flex",
                            alignItems: "center",
                            zIndex: 1
                        }}>
                            <div onMouseDown={(e) => this.rsMouseDownHandler(e)}>
                                <DragIndicatorIcon sx={{cursor: "ew-resize"}}/>
                            </div>
                        </Box>
                        {this.renderWidget()}
                    </Card>
                </Box>
            </Slide>
            <Drawer variant="permanent" anchor="right" PaperProps={{sx: {width: 50, marginTop: "61px"}}}>
                <Box className={styles.sideToolbar}>
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={this.state.view}
                        exclusive
                        onChange={(e, v) => this.handleChange(v)}
                        sx={{flexGrow: "1"}}
                    >
                        <ToggleButton value="info" aria-label="info">
                            <InfoIcon/>
                        </ToggleButton>
                        <ToggleButton value="comment" aria-label="comments">
                            <CommentIcon/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box className={styles.bottom}>
                        <LocaleSelect></LocaleSelect>
                        <ThemeColorModeContext.Consumer>
                            {(props) => {
                                return <IconButton onClick={() => props.toggleColorMode && props.toggleColorMode()} color="secondary">
                                    {props.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
                                </IconButton>
                            }}
                        </ThemeColorModeContext.Consumer>
                    </Box>
                </Box>
            </Drawer>
        </>
    }
}
