import parse from "html-react-parser";
import {ButtonBase, Card, CardContent, CardHeader, Divider} from "@mui/material";
import dayjs from "dayjs";
import {useUserDataService} from "shared/hooks/services/useUserDataService";
import {Comment} from "shared/graphql/API";

interface CommentProps {
    comment: Comment
    onDelete: Function
    onEdit: Function
}

export const UserComment = (props: CommentProps) => {
    const {getUsername} = useUserDataService();

    const renderEditButton = () => {
        return <ButtonBase onClick={() => props.onEdit()} sx={{fontSize: "inherit"}}>edit</ButtonBase>
    }

    const renderDeleteButton = () => {
        return <ButtonBase onClick={() => props.onDelete()} sx={{fontSize: "inherit"}}>delete</ButtonBase>
    }

    const renderActions = () => {
        if (getUsername() === props.comment.owner) {
            return <>&#x2022; {renderEditButton()} &#x2022; {renderDeleteButton()}</>
        }
    }

    const createSubtitle = () => {
        return <div>
            {props.comment.ownerName} &#x2022; {dayjs(props.comment.updatedAt).fromNow()} {renderActions()}
        </div>
    }

    return <Card sx={{width: "100%"}}>
        <CardHeader subheader={createSubtitle()}/>
        <Divider></Divider>
        <CardContent sx={{overflow: "auto", padding: "1rem"}}>
            {parse(props.comment.content)}
        </CardContent>
    </Card>
}
