import {
    CreateInitiativeInput,
    CreateInitiativeMutation,
    GetInitiativeQuery,
    Initiative,
    ListInitiativesQuery,
    ListInitiativesQueryVariables,
    UpdateInitiativeInput,
    UpdateInitiativeMutation
} from "shared/graphql/API";
import {GraphQLQuery} from "@aws-amplify/api";
import {createInitiative as persistInitiative, updateInitiative as persistUpdateInitiative} from "../../graphql/mutations";
import {useUserContext} from "shared/context/UserContext";
import {getInitiative, listInitiatives as listInitiativesOperation} from "../../graphql/queries";
import {useAuditService} from "./AuditService";
import {useCallback} from "react";


export const useInitiativeService = () => {
    const {appendTemplateAudits} = useAuditService();
    const {client} = useUserContext();

    const searchInitiatives = useCallback(async (variables: ListInitiativesQueryVariables): Promise<Array<Initiative>> => {
        const {data} = await client.graphql<GraphQLQuery<ListInitiativesQuery>>({
            query: listInitiativesOperation,
            variables
        });
        return data?.listInitiatives?.items as Array<Initiative>;
    }, [client]);

    const listInitiatives = useCallback(async (variables?: ListInitiativesQueryVariables): Promise<Array<Initiative>> => {
        const {data} = await client.graphql<GraphQLQuery<ListInitiativesQuery>>({
            query: listInitiativesOperation,
            variables
        });
        return data?.listInitiatives?.items as Array<Initiative>;
    }, [client])

    const getInitiativeById = useCallback(async (initiativeId: string): Promise<Initiative> => {
        const result = await client.graphql<GraphQLQuery<GetInitiativeQuery>>({
            query: getInitiative,
            variables: {id: initiativeId},
        });
        return result.data?.getInitiative as Initiative;
    }, [client])

    const createInitiative = useCallback(async (initiativeData: CreateInitiativeInput) => {
        const result = await client.graphql<GraphQLQuery<CreateInitiativeMutation>>({
            query: persistInitiative,
            variables: {input: initiativeData},
        });
        return result.data?.createInitiative as Initiative;
    }, [client]);

    const createInitiativeWithTemplateAudits = useCallback(async (initiativeData: CreateInitiativeInput): Promise<Initiative> => {
        return await createInitiative(initiativeData).then((c: Initiative) => appendTemplateAudits(c.id).then(() => Promise.resolve(c)));
    }, [appendTemplateAudits, createInitiative]);

    const updateInitiative = useCallback(async (initiativeData: UpdateInitiativeInput): Promise<Initiative> => {
        const result = await client.graphql<GraphQLQuery<UpdateInitiativeMutation>>({
            query: persistUpdateInitiative,
            variables: {input: initiativeData},
        });
        return result.data?.updateInitiative as Initiative;
    }, [client]);

    return {updateInitiative, getInitiativeById, createInitiative, createInitiativeWithTemplateAudits, listInitiatives, searchInitiatives}
}
