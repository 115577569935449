import styles from "../../pages/initiative/components/initiative-card/initiative-card.module.scss";
import {Chip, Tooltip} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {OverridableStringUnion} from "@mui/types";
import {ChipPropsColorOverrides} from "@mui/material/Chip/Chip";

interface GenericStatusProps {
    updatedAt?: string | null,
    ownerName?: string | null,
    lastEditor?: string | null,
    status?: string,
    statusColor?: OverridableStringUnion<'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning', ChipPropsColorOverrides>,
}

export const GenericStatus = (props: GenericStatusProps) => {
    const {t} = useTranslation();
    const lasUpdatedAt = () => {
        if (props.updatedAt) {
            const i18nPrefix = t("content.common.updatedAt");
            const updatedAt = i18nPrefix + dayjs(props.updatedAt).fromNow();
            return <Tooltip title={dayjs(props.updatedAt).format().toString()}>
                <Chip label={updatedAt}/>
            </Tooltip>
        }
    }
    const createdFrom = () => {
        if (props.ownerName) {
            const i18nPrefix = t("content.common.createdFrom");
            return <Chip label={i18nPrefix + (props.ownerName)}/>
        }
    }
    const getUpdatedFrom = () => {
        if (props.lastEditor) {
            const i18nPrefix = t("content.common.updatedFrom");
            return <Chip label={i18nPrefix + (props.lastEditor)}/>
        }
    }
    const renderStatus = () => {
        return <div className={styles.initiativeStatusContainer}>
            {props.status && <Chip className={styles.initiativeStatus} color={props.statusColor} label={t(`content.status.${props.status}`)}/>}
            {createdFrom()}
            {getUpdatedFrom()}
            {lasUpdatedAt()}
        </div>
    }

    return renderStatus();
}
