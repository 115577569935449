import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {Audit, AuditPhase, Initiative} from "shared/graphql/API";
import {useUserContext} from "./UserContext";
import {Notification} from "../helpers/notification/Notification";
import {useInitiativeService} from "../hooks/services/InitiativeService";
import {useAuditService} from "../hooks/services/AuditService";
import {isNotBlank} from "../helpers/CommonUtils";


type InitiativeContextType = {
    currentInitiative?: Initiative,
    audits: Array<Audit>,
    updateAudits: Function,
    updateInitiative: Function,
    setInitiative: Function,
    setAudits: Function,
    updating: boolean,
    setUpdating: Function,
    currentPhase: AuditPhase,
    setCurrentPhase: Function,
}

const InitiativeContext = createContext<InitiativeContextType | null>(null);

type InitiativeContextProviderProps = {
    initiativeId?: string,
    children: ReactNode
}

const InitiativeContextProvider = ({initiativeId, children}: InitiativeContextProviderProps) => {
    const {getInitiativeById} = useInitiativeService();
    const {getAuditsByInitiativeID} = useAuditService();
    const userContext = useUserContext();
    const [currentInitiative, setInitiative] = useState<Initiative>();
    const [currentPhase, setCurrentPhase] = useState<AuditPhase>(AuditPhase.basic);
    const [audits, setAudits] = useState<Array<Audit>>([]);
    const [updating, setUpdating] = useState<boolean>(false);

    useEffect(() => {
        const retrieveInitiative = async () => {
            if (initiativeId) {
                const initiativeData:Initiative = await getInitiativeById(initiativeId);
                setInitiative(initiativeData);
                setCurrentPhase(initiativeData?.phase);
                setAudits(initiativeData.audits?.items as Array<Audit>);
            }
        }
        setUpdating(true);
        retrieveInitiative()
            .catch((e) => Notification.error("Could not get initiative information. Try later.", e))
            .finally(() => setUpdating(false));
    }, [initiativeId, getInitiativeById, userContext.user])

    const updateAudits = useCallback(async () => {
        if (initiativeId && isNotBlank(initiativeId)) {
            setUpdating(true);
            getAuditsByInitiativeID(initiativeId)
                .then(a => setAudits(a))
                .catch((e) => Notification.error("Could not update audits. Try later.", e))
                .finally(() => setUpdating(false));
        }
    }, [initiativeId, getAuditsByInitiativeID])

    const updateInitiative = useCallback(() => {
        if (initiativeId) {
            setUpdating(true);
            getInitiativeById(initiativeId)
                .then(c => setInitiative(c))
                .finally(() => setUpdating(false));
        }
    }, [initiativeId, getInitiativeById])

    return (
        <InitiativeContext.Provider value={{currentInitiative: currentInitiative, audits: audits, updateAudits, updateInitiative, setInitiative, setAudits, updating, setUpdating, currentPhase, setCurrentPhase}}>
            {children}
        </InitiativeContext.Provider>
    );
};


const useInitiativeData = (): InitiativeContextType => {
    const context = useContext(InitiativeContext);
    if (context === null) {
        throw new Error("InitiativeContext was used outside of its Provider");
    }
    return context;
}

export {InitiativeContext, InitiativeContextProvider, useInitiativeData};
