import styles from "../../pages/home/home-page.module.scss";
import React, {useEffect, useRef, useState} from "react";
import PushPinIcon from '@mui/icons-material/PushPin';
import {NewsCard} from "./NewsCard";
import useOnScreen from "shared/hooks/useOnScreen";
import {Grow} from "@mui/material";
import {useArticleService} from "shared/hooks/services/ArticleService";
import {Article} from "shared/graphql/API";
import _ from "lodash";
import {NewsWidgetCardSkeleton} from "./NewsCardSkeleton";

type NewsWidgetProps = {
    className: string
}

export const NewsWidget = ({className}: NewsWidgetProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref, true);
    const {searchArticles} = useArticleService();
    const [articles, setArticles] = useState<Array<Article>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // searchArticles({
        //     filter: {
        //         active: {eq: true}
        //     },
        //     sort: [
        //         {field: SearchableArticleSortableFields.pinned, direction: SearchableSortDirection.desc},
        //         {field: SearchableArticleSortableFields.createdAt, direction: SearchableSortDirection.desc},
        //     ],
        //     limit: 5
        // })
        //     .then(a => setArticles(a))
        //     .finally(() => setLoading(false));
    }, [searchArticles]);

    const renderNews = () => {
        if (loading)
            return Array.from({length: _.random(7, 10)}).map(() => <NewsWidgetCardSkeleton key={Math.random()}/>)
        return articles.map((a) =>
            <NewsCard key={a.id}
                      article={a}
                      shorten
                      action={
                          <>
                              {a.pinned && <PushPinIcon color={"secondary"}/>}
                          </>
                      }
                      to={`/news/${a.id}`}
            />
        )
    }

    return <Grow in={isVisible} timeout={1000}>
        <div ref={ref} className={className}>
            <div className={styles.newsContent}>
                {renderNews()}
            </div>
        </div>
    </Grow>
}
