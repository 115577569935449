import React from "react";
import AnswerAuditReference from "./AnswerAuditReference";
import {Button, IconButton, List, ListItem, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./answer.module.scss";
import {useAuditData} from "shared/context/AuditEditorContext";
import {useTranslation} from "react-i18next";
import {AnswerValue} from "shared/graphql";

type AnswerValuesEditorProps = {
    answerValues: Array<AnswerValue> | [];
    className?: any;
    onChange?: Function;
}

export const AnswerValuesEditor = (props: AnswerValuesEditorProps) => {
    const {answerValues} = props;
    const {onChange} = props;
    const {t} = useTranslation();
    const audit = useAuditData();

    const addSelection = () => {
        let temp = [...answerValues];
        temp.push({value: "", selected: false, auditReferences: []});
        onChange && onChange(temp);
    }

    const removeSelection = (index: number) => {
        const temp = [...answerValues];
        temp.splice(index, 1);
        onChange && onChange(temp);
    }

    const changeSelectionValue = (index: number, selectionValue: string, references: Array<string>) => {
        const temp = [...answerValues];
        temp[index] = {selected: false, value: selectionValue, auditReferences: references}
        onChange && onChange(temp);
    }

    return <div className={styles.selectionsContainer}>
        <Button onClick={() => addSelection()} variant={"contained"}>{t("action.createEntry")}</Button>
        <List>
            {answerValues?.filter((x: AnswerValue): x is AnswerValue => x !== null)
                .map((a: AnswerValue, index: number) =>
                    <ListItem key={audit.id + index}
                              alignItems={"flex-start"}
                              disableGutters
                              secondaryAction={
                                  <IconButton edge="end" aria-label="delete" onClick={() => removeSelection(index)}>
                                      <DeleteIcon/>
                                  </IconButton>
                              }
                    >
                        <TextField className={styles.selectionText}
                                   label={t("content.common.selection")}
                                   defaultValue={a.value}
                                   onChange={(e) => changeSelectionValue(index, e.target.value, [])}
                        />
                        <AnswerAuditReference className={styles.selectionReference}
                                              answerValue={a}
                                              onChange={(v: Array<string>) => changeSelectionValue(index, a.value || "", v)}
                        />
                    </ListItem>
                )
            }
        </List>
    </div>
}
