/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelArticleConditionInput = {
  active?: ModelBooleanInput | null,
  and?: Array< ModelArticleConditionInput | null > | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  introduction?: ModelStringInput | null,
  not?: ModelArticleConditionInput | null,
  or?: Array< ModelArticleConditionInput | null > | null,
  pinned?: ModelBooleanInput | null,
  thumbnail?: ModelStringInput | null,
  title?: ModelStringInput | null,
  topics?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBooleanInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  eq?: boolean | null,
  ne?: boolean | null,
};

export enum ModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
}


export type ModelStringInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type CreateArticleInput = {
  active: boolean,
  content: string,
  id?: string | null,
  introduction: string,
  pinned: boolean,
  thumbnail?: string | null,
  title: string,
  topics: Array< string >,
};

export type Article = {
  __typename: "Article",
  active: boolean,
  comments?: ModelCommentConnection | null,
  content: string,
  createdAt: string,
  id: string,
  introduction: string,
  pinned: boolean,
  thumbnail?: string | null,
  title: string,
  topics: Array< string >,
  updatedAt: string,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  article?: Article | null,
  articleId?: string | null,
  content: string,
  createdAt: string,
  id: string,
  owner?: string | null,
  ownerName: string,
  question?: Question | null,
  questionId?: string | null,
  updatedAt: string,
};

export type Question = {
  __typename: "Question",
  answer: Answer,
  audit?: Audit | null,
  auditId?: string | null,
  comments?: ModelCommentConnection | null,
  createdAt: string,
  description?: string | null,
  editors?: string | null,
  favourite?: boolean | null,
  id: string,
  owner?: string | null,
  question: string,
  updatedAt: string,
};

export type Answer = {
  __typename: "Answer",
  type?: AnswerType | null,
  values:  Array<AnswerValue >,
};

export enum AnswerType {
  check = "check",
  radio = "radio",
  text = "text",
}


export type AnswerValue = {
  __typename: "AnswerValue",
  auditReferences: Array< string | null >,
  selected: boolean,
  value: string,
};

export type Audit = {
  __typename: "Audit",
  createdAt: string,
  description: string,
  editors?: Array< string | null > | null,
  id: string,
  initiative?: Initiative | null,
  initiativeId?: string | null,
  isActive: boolean,
  isTemplate: boolean,
  lastEditor?: string | null,
  name: string,
  originAuditId?: string | null,
  owner?: string | null,
  ownerName?: string | null,
  phase: AuditPhase,
  questions?: ModelQuestionConnection | null,
  updatedAt: string,
};

export type Initiative = {
  __typename: "Initiative",
  audits?: ModelAuditConnection | null,
  cloudEnvironments?: Array< CloudEnvironment | null > | null,
  cloudProvider: CloudProvider,
  costUnit?: string | null,
  createdAt: string,
  description: string,
  editors?: Array< string | null > | null,
  id: string,
  key: string,
  lastEditor?: string | null,
  name: string,
  owner?: string | null,
  ownerName?: string | null,
  phase: AuditPhase,
  projectOwner?: EntraIDUser | null,
  projectResponsible?: EntraIDUser | null,
  riscOfficer?: EntraIDUser | null,
  serviceType: CloudServiceType,
  status: InitiativeStatus,
  updatedAt: string,
};

export type ModelAuditConnection = {
  __typename: "ModelAuditConnection",
  items:  Array<Audit | null >,
  nextToken?: string | null,
};

export enum CloudEnvironment {
  develop = "develop",
  production = "production",
  sandbox = "sandbox",
  staging = "staging",
  test = "test",
}


export enum CloudProvider {
  atlassian = "atlassian",
  aws = "aws",
  azure = "azure",
  other = "other",
  snowflake = "snowflake",
}


export enum AuditPhase {
  basic = "basic",
  specific = "specific",
  thematic = "thematic",
}


export type EntraIDUser = {
  __typename: "EntraIDUser",
  department?: string | null,
  displayName: string,
  id: string,
  mail: string,
};

export enum CloudServiceType {
  IAAS = "IAAS",
  PAAS = "PAAS",
  SAAS = "SAAS",
}


export enum InitiativeStatus {
  cancelled = "cancelled",
  completed = "completed",
  inProgress = "inProgress",
  inRecertification = "inRecertification",
  inReview = "inReview",
  open = "open",
}


export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type ModelAuditConditionInput = {
  and?: Array< ModelAuditConditionInput | null > | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  initiativeId?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  isTemplate?: ModelBooleanInput | null,
  lastEditor?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelAuditConditionInput | null,
  or?: Array< ModelAuditConditionInput | null > | null,
  originAuditId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  phase?: ModelAuditPhaseInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export type ModelAuditPhaseInput = {
  eq?: AuditPhase | null,
  ne?: AuditPhase | null,
};

export type CreateAuditInput = {
  description: string,
  editors?: Array< string | null > | null,
  id?: string | null,
  initiativeId?: string | null,
  isActive: boolean,
  isTemplate: boolean,
  lastEditor?: string | null,
  name: string,
  originAuditId?: string | null,
  owner?: string | null,
  ownerName?: string | null,
  phase: AuditPhase,
};

export type ModelCommentConditionInput = {
  and?: Array< ModelCommentConditionInput | null > | null,
  articleId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  not?: ModelCommentConditionInput | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  questionId?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
};

export type CreateCommentInput = {
  articleId?: string | null,
  content: string,
  id?: string | null,
  owner?: string | null,
  ownerName: string,
  questionId?: string | null,
};

export type ModelInitiativeConditionInput = {
  and?: Array< ModelInitiativeConditionInput | null > | null,
  cloudEnvironments?: ModelCloudEnvironmentListInput | null,
  cloudProvider?: ModelCloudProviderInput | null,
  costUnit?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  key?: ModelStringInput | null,
  lastEditor?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelInitiativeConditionInput | null,
  or?: Array< ModelInitiativeConditionInput | null > | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  phase?: ModelAuditPhaseInput | null,
  serviceType?: ModelCloudServiceTypeInput | null,
  status?: ModelInitiativeStatusInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelCloudEnvironmentListInput = {
  contains?: CloudEnvironment | null,
  eq?: Array< CloudEnvironment | null > | null,
  ne?: Array< CloudEnvironment | null > | null,
  notContains?: CloudEnvironment | null,
};

export type ModelCloudProviderInput = {
  eq?: CloudProvider | null,
  ne?: CloudProvider | null,
};

export type ModelCloudServiceTypeInput = {
  eq?: CloudServiceType | null,
  ne?: CloudServiceType | null,
};

export type ModelInitiativeStatusInput = {
  eq?: InitiativeStatus | null,
  ne?: InitiativeStatus | null,
};

export type CreateInitiativeInput = {
  cloudEnvironments?: Array< CloudEnvironment | null > | null,
  cloudProvider: CloudProvider,
  costUnit?: string | null,
  description: string,
  editors?: Array< string | null > | null,
  id?: string | null,
  key: string,
  lastEditor?: string | null,
  name: string,
  owner?: string | null,
  ownerName?: string | null,
  phase: AuditPhase,
  projectOwner?: EntraIDUserInput | null,
  projectResponsible?: EntraIDUserInput | null,
  riscOfficer?: EntraIDUserInput | null,
  serviceType: CloudServiceType,
  status: InitiativeStatus,
};

export type EntraIDUserInput = {
  department?: string | null,
  displayName: string,
  id: string,
  mail: string,
};

export type ModelQuestionConditionInput = {
  and?: Array< ModelQuestionConditionInput | null > | null,
  auditId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  favourite?: ModelBooleanInput | null,
  not?: ModelQuestionConditionInput | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  owner?: ModelStringInput | null,
  question?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type CreateQuestionInput = {
  answer: AnswerInput,
  auditId?: string | null,
  description?: string | null,
  favourite?: boolean | null,
  id?: string | null,
  question: string,
};

export type AnswerInput = {
  type?: AnswerType | null,
  values: Array< AnswerValueInput >,
};

export type AnswerValueInput = {
  auditReferences: Array< string | null >,
  selected: boolean,
  value: string,
};

export type DeleteArticleInput = {
  id: string,
};

export type DeleteAuditInput = {
  id: string,
};

export type DeleteCommentInput = {
  id: string,
};

export type DeleteInitiativeInput = {
  id: string,
};

export type DeleteQuestionInput = {
  id: string,
};

export type UpdateArticleInput = {
  active?: boolean | null,
  content?: string | null,
  id: string,
  introduction?: string | null,
  pinned?: boolean | null,
  thumbnail?: string | null,
  title?: string | null,
  topics?: Array< string > | null,
};

export type UpdateAuditInput = {
  description?: string | null,
  editors?: Array< string | null > | null,
  id: string,
  initiativeId?: string | null,
  isActive?: boolean | null,
  isTemplate?: boolean | null,
  lastEditor?: string | null,
  name?: string | null,
  originAuditId?: string | null,
  owner?: string | null,
  ownerName?: string | null,
  phase?: AuditPhase | null,
};

export type UpdateCommentInput = {
  articleId?: string | null,
  content?: string | null,
  id: string,
  owner?: string | null,
  ownerName?: string | null,
  questionId?: string | null,
};

export type UpdateInitiativeInput = {
  cloudEnvironments?: Array< CloudEnvironment | null > | null,
  cloudProvider?: CloudProvider | null,
  costUnit?: string | null,
  description?: string | null,
  editors?: Array< string | null > | null,
  id: string,
  key?: string | null,
  lastEditor?: string | null,
  name?: string | null,
  owner?: string | null,
  ownerName?: string | null,
  phase?: AuditPhase | null,
  projectOwner?: EntraIDUserInput | null,
  projectResponsible?: EntraIDUserInput | null,
  riscOfficer?: EntraIDUserInput | null,
  serviceType?: CloudServiceType | null,
  status?: InitiativeStatus | null,
};

export type UpdateQuestionInput = {
  answer?: AnswerInput | null,
  auditId?: string | null,
  description?: string | null,
  favourite?: boolean | null,
  id: string,
  question?: string | null,
};

export type ModelInitiativeFilterInput = {
  and?: Array< ModelInitiativeFilterInput | null > | null,
  cloudEnvironments?: ModelCloudEnvironmentListInput | null,
  cloudProvider?: ModelCloudProviderInput | null,
  costUnit?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  id?: ModelIDInput | null,
  key?: ModelStringInput | null,
  lastEditor?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelInitiativeFilterInput | null,
  or?: Array< ModelInitiativeFilterInput | null > | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  phase?: ModelAuditPhaseInput | null,
  serviceType?: ModelCloudServiceTypeInput | null,
  status?: ModelInitiativeStatusInput | null,
  updatedAt?: ModelStringInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelInitiativeConnection = {
  __typename: "ModelInitiativeConnection",
  items:  Array<Initiative | null >,
  nextToken?: string | null,
};

export type ModelArticleFilterInput = {
  active?: ModelBooleanInput | null,
  and?: Array< ModelArticleFilterInput | null > | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  introduction?: ModelStringInput | null,
  not?: ModelArticleFilterInput | null,
  or?: Array< ModelArticleFilterInput | null > | null,
  pinned?: ModelBooleanInput | null,
  thumbnail?: ModelStringInput | null,
  title?: ModelStringInput | null,
  topics?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelArticleConnection = {
  __typename: "ModelArticleConnection",
  items:  Array<Article | null >,
  nextToken?: string | null,
};

export type ModelAuditFilterInput = {
  and?: Array< ModelAuditFilterInput | null > | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  id?: ModelIDInput | null,
  initiativeId?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  isTemplate?: ModelBooleanInput | null,
  lastEditor?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelAuditFilterInput | null,
  or?: Array< ModelAuditFilterInput | null > | null,
  originAuditId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  phase?: ModelAuditPhaseInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelCommentFilterInput = {
  and?: Array< ModelCommentFilterInput | null > | null,
  articleId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  not?: ModelCommentFilterInput | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  questionId?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelQuestionFilterInput = {
  and?: Array< ModelQuestionFilterInput | null > | null,
  auditId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  favourite?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  not?: ModelQuestionFilterInput | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  owner?: ModelStringInput | null,
  question?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSubscriptionArticleFilterInput = {
  active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionArticleFilterInput | null > | null,
  content?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  introduction?: ModelSubscriptionStringInput | null,
  or?: Array< ModelSubscriptionArticleFilterInput | null > | null,
  pinned?: ModelSubscriptionBooleanInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  topics?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
};

export type ModelSubscriptionBooleanInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type ModelSubscriptionStringInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  in?: Array< string | null > | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  in?: Array< string | null > | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionAuditFilterInput = {
  and?: Array< ModelSubscriptionAuditFilterInput | null > | null,
  createdAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  editors?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  initiativeId?: ModelSubscriptionIDInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  isTemplate?: ModelSubscriptionBooleanInput | null,
  lastEditor?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  or?: Array< ModelSubscriptionAuditFilterInput | null > | null,
  originAuditId?: ModelSubscriptionIDInput | null,
  owner?: ModelSubscriptionStringInput | null,
  ownerName?: ModelSubscriptionStringInput | null,
  phase?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
};

export type ModelSubscriptionCommentFilterInput = {
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  articleId?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelSubscriptionStringInput | null,
  questionId?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
};

export type ModelSubscriptionInitiativeFilterInput = {
  and?: Array< ModelSubscriptionInitiativeFilterInput | null > | null,
  cloudEnvironments?: ModelSubscriptionStringInput | null,
  cloudProvider?: ModelSubscriptionStringInput | null,
  costUnit?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  editors?: ModelStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  key?: ModelSubscriptionStringInput | null,
  lastEditor?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  or?: Array< ModelSubscriptionInitiativeFilterInput | null > | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelSubscriptionStringInput | null,
  phase?: ModelSubscriptionStringInput | null,
  serviceType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
};

export type ModelSubscriptionQuestionFilterInput = {
  and?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  auditId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  editors?: ModelStringInput | null,
  favourite?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  or?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  owner?: ModelStringInput | null,
  question?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
};

export type CreateArticleMutationVariables = {
  condition?: ModelArticleConditionInput | null,
  input: CreateArticleInput,
};

export type CreateArticleMutation = {
  createArticle?:  {
    __typename: "Article",
    active: boolean,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    content: string,
    createdAt: string,
    id: string,
    introduction: string,
    pinned: boolean,
    thumbnail?: string | null,
    title: string,
    topics: Array< string >,
    updatedAt: string,
  } | null,
};

export type CreateAuditMutationVariables = {
  condition?: ModelAuditConditionInput | null,
  input: CreateAuditInput,
};

export type CreateAuditMutation = {
  createAudit?:  {
    __typename: "Audit",
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    initiative?:  {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null,
    initiativeId?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    lastEditor?: string | null,
    name: string,
    originAuditId?: string | null,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateCommentMutationVariables = {
  condition?: ModelCommentConditionInput | null,
  input: CreateCommentInput,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    article?:  {
      __typename: "Article",
      active: boolean,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      content: string,
      createdAt: string,
      id: string,
      introduction: string,
      pinned: boolean,
      thumbnail?: string | null,
      title: string,
      topics: Array< string >,
      updatedAt: string,
    } | null,
    articleId?: string | null,
    content: string,
    createdAt: string,
    id: string,
    owner?: string | null,
    ownerName: string,
    question?:  {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null,
    questionId?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateInitiativeMutationVariables = {
  condition?: ModelInitiativeConditionInput | null,
  input: CreateInitiativeInput,
};

export type CreateInitiativeMutation = {
  createInitiative?:  {
    __typename: "Initiative",
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cloudEnvironments?: Array< CloudEnvironment | null > | null,
    cloudProvider: CloudProvider,
    costUnit?: string | null,
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    key: string,
    lastEditor?: string | null,
    name: string,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    projectOwner?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    projectResponsible?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    riscOfficer?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    serviceType: CloudServiceType,
    status: InitiativeStatus,
    updatedAt: string,
  } | null,
};

export type CreateQuestionMutationVariables = {
  condition?: ModelQuestionConditionInput | null,
  input: CreateQuestionInput,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    answer:  {
      __typename: "Answer",
      type?: AnswerType | null,
      values:  Array< {
        __typename: "AnswerValue",
        auditReferences: Array< string | null >,
        selected: boolean,
        value: string,
      } >,
    },
    audit?:  {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    auditId?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    editors?: string | null,
    favourite?: boolean | null,
    id: string,
    owner?: string | null,
    question: string,
    updatedAt: string,
  } | null,
};

export type DeleteArticleMutationVariables = {
  condition?: ModelArticleConditionInput | null,
  input: DeleteArticleInput,
};

export type DeleteArticleMutation = {
  deleteArticle?:  {
    __typename: "Article",
    active: boolean,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    content: string,
    createdAt: string,
    id: string,
    introduction: string,
    pinned: boolean,
    thumbnail?: string | null,
    title: string,
    topics: Array< string >,
    updatedAt: string,
  } | null,
};

export type DeleteAuditMutationVariables = {
  condition?: ModelAuditConditionInput | null,
  input: DeleteAuditInput,
};

export type DeleteAuditMutation = {
  deleteAudit?:  {
    __typename: "Audit",
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    initiative?:  {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null,
    initiativeId?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    lastEditor?: string | null,
    name: string,
    originAuditId?: string | null,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteCommentMutationVariables = {
  condition?: ModelCommentConditionInput | null,
  input: DeleteCommentInput,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    article?:  {
      __typename: "Article",
      active: boolean,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      content: string,
      createdAt: string,
      id: string,
      introduction: string,
      pinned: boolean,
      thumbnail?: string | null,
      title: string,
      topics: Array< string >,
      updatedAt: string,
    } | null,
    articleId?: string | null,
    content: string,
    createdAt: string,
    id: string,
    owner?: string | null,
    ownerName: string,
    question?:  {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null,
    questionId?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteInitiativeMutationVariables = {
  condition?: ModelInitiativeConditionInput | null,
  input: DeleteInitiativeInput,
};

export type DeleteInitiativeMutation = {
  deleteInitiative?:  {
    __typename: "Initiative",
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cloudEnvironments?: Array< CloudEnvironment | null > | null,
    cloudProvider: CloudProvider,
    costUnit?: string | null,
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    key: string,
    lastEditor?: string | null,
    name: string,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    projectOwner?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    projectResponsible?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    riscOfficer?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    serviceType: CloudServiceType,
    status: InitiativeStatus,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  condition?: ModelQuestionConditionInput | null,
  input: DeleteQuestionInput,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    answer:  {
      __typename: "Answer",
      type?: AnswerType | null,
      values:  Array< {
        __typename: "AnswerValue",
        auditReferences: Array< string | null >,
        selected: boolean,
        value: string,
      } >,
    },
    audit?:  {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    auditId?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    editors?: string | null,
    favourite?: boolean | null,
    id: string,
    owner?: string | null,
    question: string,
    updatedAt: string,
  } | null,
};

export type UpdateArticleMutationVariables = {
  condition?: ModelArticleConditionInput | null,
  input: UpdateArticleInput,
};

export type UpdateArticleMutation = {
  updateArticle?:  {
    __typename: "Article",
    active: boolean,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    content: string,
    createdAt: string,
    id: string,
    introduction: string,
    pinned: boolean,
    thumbnail?: string | null,
    title: string,
    topics: Array< string >,
    updatedAt: string,
  } | null,
};

export type UpdateAuditMutationVariables = {
  condition?: ModelAuditConditionInput | null,
  input: UpdateAuditInput,
};

export type UpdateAuditMutation = {
  updateAudit?:  {
    __typename: "Audit",
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    initiative?:  {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null,
    initiativeId?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    lastEditor?: string | null,
    name: string,
    originAuditId?: string | null,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateCommentMutationVariables = {
  condition?: ModelCommentConditionInput | null,
  input: UpdateCommentInput,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    article?:  {
      __typename: "Article",
      active: boolean,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      content: string,
      createdAt: string,
      id: string,
      introduction: string,
      pinned: boolean,
      thumbnail?: string | null,
      title: string,
      topics: Array< string >,
      updatedAt: string,
    } | null,
    articleId?: string | null,
    content: string,
    createdAt: string,
    id: string,
    owner?: string | null,
    ownerName: string,
    question?:  {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null,
    questionId?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateInitiativeMutationVariables = {
  condition?: ModelInitiativeConditionInput | null,
  input: UpdateInitiativeInput,
};

export type UpdateInitiativeMutation = {
  updateInitiative?:  {
    __typename: "Initiative",
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cloudEnvironments?: Array< CloudEnvironment | null > | null,
    cloudProvider: CloudProvider,
    costUnit?: string | null,
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    key: string,
    lastEditor?: string | null,
    name: string,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    projectOwner?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    projectResponsible?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    riscOfficer?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    serviceType: CloudServiceType,
    status: InitiativeStatus,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  condition?: ModelQuestionConditionInput | null,
  input: UpdateQuestionInput,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    answer:  {
      __typename: "Answer",
      type?: AnswerType | null,
      values:  Array< {
        __typename: "AnswerValue",
        auditReferences: Array< string | null >,
        selected: boolean,
        value: string,
      } >,
    },
    audit?:  {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    auditId?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    editors?: string | null,
    favourite?: boolean | null,
    id: string,
    owner?: string | null,
    question: string,
    updatedAt: string,
  } | null,
};

export type GetArticleQueryVariables = {
  id: string,
};

export type GetArticleQuery = {
  getArticle?:  {
    __typename: "Article",
    active: boolean,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    content: string,
    createdAt: string,
    id: string,
    introduction: string,
    pinned: boolean,
    thumbnail?: string | null,
    title: string,
    topics: Array< string >,
    updatedAt: string,
  } | null,
};

export type GetAuditQueryVariables = {
  id: string,
};

export type GetAuditQuery = {
  getAudit?:  {
    __typename: "Audit",
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    initiative?:  {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null,
    initiativeId?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    lastEditor?: string | null,
    name: string,
    originAuditId?: string | null,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    article?:  {
      __typename: "Article",
      active: boolean,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      content: string,
      createdAt: string,
      id: string,
      introduction: string,
      pinned: boolean,
      thumbnail?: string | null,
      title: string,
      topics: Array< string >,
      updatedAt: string,
    } | null,
    articleId?: string | null,
    content: string,
    createdAt: string,
    id: string,
    owner?: string | null,
    ownerName: string,
    question?:  {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null,
    questionId?: string | null,
    updatedAt: string,
  } | null,
};

export type GetInitiativeQueryVariables = {
  id: string,
};

export type GetInitiativeQuery = {
  getInitiative?:  {
    __typename: "Initiative",
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cloudEnvironments?: Array< CloudEnvironment | null > | null,
    cloudProvider: CloudProvider,
    costUnit?: string | null,
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    key: string,
    lastEditor?: string | null,
    name: string,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    projectOwner?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    projectResponsible?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    riscOfficer?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    serviceType: CloudServiceType,
    status: InitiativeStatus,
    updatedAt: string,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    answer:  {
      __typename: "Answer",
      type?: AnswerType | null,
      values:  Array< {
        __typename: "AnswerValue",
        auditReferences: Array< string | null >,
        selected: boolean,
        value: string,
      } >,
    },
    audit?:  {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    auditId?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    editors?: string | null,
    favourite?: boolean | null,
    id: string,
    owner?: string | null,
    question: string,
    updatedAt: string,
  } | null,
};

export type InitiativesByKeyQueryVariables = {
  filter?: ModelInitiativeFilterInput | null,
  key: string,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type InitiativesByKeyQuery = {
  initiativesByKey?:  {
    __typename: "ModelInitiativeConnection",
    items:  Array< {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListArticlesQueryVariables = {
  filter?: ModelArticleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticlesQuery = {
  listArticles?:  {
    __typename: "ModelArticleConnection",
    items:  Array< {
      __typename: "Article",
      active: boolean,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      content: string,
      createdAt: string,
      id: string,
      introduction: string,
      pinned: boolean,
      thumbnail?: string | null,
      title: string,
      topics: Array< string >,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAuditByInitiativeIdQueryVariables = {
  filter?: ModelAuditFilterInput | null,
  initiativeId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAuditByInitiativeIdQuery = {
  listAuditByInitiativeId?:  {
    __typename: "ModelAuditConnection",
    items:  Array< {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAuditsQueryVariables = {
  filter?: ModelAuditFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuditsQuery = {
  listAudits?:  {
    __typename: "ModelAuditConnection",
    items:  Array< {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCommentByQuestionIdQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  questionId: string,
  sortDirection?: ModelSortDirection | null,
};

export type ListCommentByQuestionIdQuery = {
  listCommentByQuestionId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      article?:  {
        __typename: "Article",
        active: boolean,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        content: string,
        createdAt: string,
        id: string,
        introduction: string,
        pinned: boolean,
        thumbnail?: string | null,
        title: string,
        topics: Array< string >,
        updatedAt: string,
      } | null,
      articleId?: string | null,
      content: string,
      createdAt: string,
      id: string,
      owner?: string | null,
      ownerName: string,
      question?:  {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null,
      questionId?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      article?:  {
        __typename: "Article",
        active: boolean,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        content: string,
        createdAt: string,
        id: string,
        introduction: string,
        pinned: boolean,
        thumbnail?: string | null,
        title: string,
        topics: Array< string >,
        updatedAt: string,
      } | null,
      articleId?: string | null,
      content: string,
      createdAt: string,
      id: string,
      owner?: string | null,
      ownerName: string,
      question?:  {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null,
      questionId?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInitiativesQueryVariables = {
  filter?: ModelInitiativeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInitiativesQuery = {
  listInitiatives?:  {
    __typename: "ModelInitiativeConnection",
    items:  Array< {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListQuestionByAuditIdQueryVariables = {
  auditId: string,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListQuestionByAuditIdQuery = {
  listQuestionByAuditId?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateArticleSubscriptionVariables = {
  filter?: ModelSubscriptionArticleFilterInput | null,
};

export type OnCreateArticleSubscription = {
  onCreateArticle?:  {
    __typename: "Article",
    active: boolean,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    content: string,
    createdAt: string,
    id: string,
    introduction: string,
    pinned: boolean,
    thumbnail?: string | null,
    title: string,
    topics: Array< string >,
    updatedAt: string,
  } | null,
};

export type OnCreateAuditSubscriptionVariables = {
  filter?: ModelSubscriptionAuditFilterInput | null,
};

export type OnCreateAuditSubscription = {
  onCreateAudit?:  {
    __typename: "Audit",
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    initiative?:  {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null,
    initiativeId?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    lastEditor?: string | null,
    name: string,
    originAuditId?: string | null,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    article?:  {
      __typename: "Article",
      active: boolean,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      content: string,
      createdAt: string,
      id: string,
      introduction: string,
      pinned: boolean,
      thumbnail?: string | null,
      title: string,
      topics: Array< string >,
      updatedAt: string,
    } | null,
    articleId?: string | null,
    content: string,
    createdAt: string,
    id: string,
    owner?: string | null,
    ownerName: string,
    question?:  {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null,
    questionId?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateInitiativeSubscriptionVariables = {
  filter?: ModelSubscriptionInitiativeFilterInput | null,
  owner?: string | null,
};

export type OnCreateInitiativeSubscription = {
  onCreateInitiative?:  {
    __typename: "Initiative",
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cloudEnvironments?: Array< CloudEnvironment | null > | null,
    cloudProvider: CloudProvider,
    costUnit?: string | null,
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    key: string,
    lastEditor?: string | null,
    name: string,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    projectOwner?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    projectResponsible?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    riscOfficer?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    serviceType: CloudServiceType,
    status: InitiativeStatus,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionSubscriptionVariables = {
  editors?: string | null,
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    answer:  {
      __typename: "Answer",
      type?: AnswerType | null,
      values:  Array< {
        __typename: "AnswerValue",
        auditReferences: Array< string | null >,
        selected: boolean,
        value: string,
      } >,
    },
    audit?:  {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    auditId?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    editors?: string | null,
    favourite?: boolean | null,
    id: string,
    owner?: string | null,
    question: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteArticleSubscriptionVariables = {
  filter?: ModelSubscriptionArticleFilterInput | null,
};

export type OnDeleteArticleSubscription = {
  onDeleteArticle?:  {
    __typename: "Article",
    active: boolean,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    content: string,
    createdAt: string,
    id: string,
    introduction: string,
    pinned: boolean,
    thumbnail?: string | null,
    title: string,
    topics: Array< string >,
    updatedAt: string,
  } | null,
};

export type OnDeleteAuditSubscriptionVariables = {
  filter?: ModelSubscriptionAuditFilterInput | null,
};

export type OnDeleteAuditSubscription = {
  onDeleteAudit?:  {
    __typename: "Audit",
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    initiative?:  {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null,
    initiativeId?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    lastEditor?: string | null,
    name: string,
    originAuditId?: string | null,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    article?:  {
      __typename: "Article",
      active: boolean,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      content: string,
      createdAt: string,
      id: string,
      introduction: string,
      pinned: boolean,
      thumbnail?: string | null,
      title: string,
      topics: Array< string >,
      updatedAt: string,
    } | null,
    articleId?: string | null,
    content: string,
    createdAt: string,
    id: string,
    owner?: string | null,
    ownerName: string,
    question?:  {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null,
    questionId?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteInitiativeSubscriptionVariables = {
  filter?: ModelSubscriptionInitiativeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteInitiativeSubscription = {
  onDeleteInitiative?:  {
    __typename: "Initiative",
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cloudEnvironments?: Array< CloudEnvironment | null > | null,
    cloudProvider: CloudProvider,
    costUnit?: string | null,
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    key: string,
    lastEditor?: string | null,
    name: string,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    projectOwner?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    projectResponsible?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    riscOfficer?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    serviceType: CloudServiceType,
    status: InitiativeStatus,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuestionSubscriptionVariables = {
  editors?: string | null,
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    answer:  {
      __typename: "Answer",
      type?: AnswerType | null,
      values:  Array< {
        __typename: "AnswerValue",
        auditReferences: Array< string | null >,
        selected: boolean,
        value: string,
      } >,
    },
    audit?:  {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    auditId?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    editors?: string | null,
    favourite?: boolean | null,
    id: string,
    owner?: string | null,
    question: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateArticleSubscriptionVariables = {
  filter?: ModelSubscriptionArticleFilterInput | null,
};

export type OnUpdateArticleSubscription = {
  onUpdateArticle?:  {
    __typename: "Article",
    active: boolean,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    content: string,
    createdAt: string,
    id: string,
    introduction: string,
    pinned: boolean,
    thumbnail?: string | null,
    title: string,
    topics: Array< string >,
    updatedAt: string,
  } | null,
};

export type OnUpdateAuditSubscriptionVariables = {
  filter?: ModelSubscriptionAuditFilterInput | null,
};

export type OnUpdateAuditSubscription = {
  onUpdateAudit?:  {
    __typename: "Audit",
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    initiative?:  {
      __typename: "Initiative",
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cloudEnvironments?: Array< CloudEnvironment | null > | null,
      cloudProvider: CloudProvider,
      costUnit?: string | null,
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      key: string,
      lastEditor?: string | null,
      name: string,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      projectOwner?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      projectResponsible?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      riscOfficer?:  {
        __typename: "EntraIDUser",
        department?: string | null,
        displayName: string,
        id: string,
        mail: string,
      } | null,
      serviceType: CloudServiceType,
      status: InitiativeStatus,
      updatedAt: string,
    } | null,
    initiativeId?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    lastEditor?: string | null,
    name: string,
    originAuditId?: string | null,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
        },
        audit?:  {
          __typename: "Audit",
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          initiativeId?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          lastEditor?: string | null,
          name: string,
          originAuditId?: string | null,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          updatedAt: string,
        } | null,
        auditId?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        description?: string | null,
        editors?: string | null,
        favourite?: boolean | null,
        id: string,
        owner?: string | null,
        question: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    article?:  {
      __typename: "Article",
      active: boolean,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      content: string,
      createdAt: string,
      id: string,
      introduction: string,
      pinned: boolean,
      thumbnail?: string | null,
      title: string,
      topics: Array< string >,
      updatedAt: string,
    } | null,
    articleId?: string | null,
    content: string,
    createdAt: string,
    id: string,
    owner?: string | null,
    ownerName: string,
    question?:  {
      __typename: "Question",
      answer:  {
        __typename: "Answer",
        type?: AnswerType | null,
        values:  Array< {
          __typename: "AnswerValue",
          auditReferences: Array< string | null >,
          selected: boolean,
          value: string,
        } >,
      },
      audit?:  {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      auditId?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          articleId?: string | null,
          content: string,
          createdAt: string,
          id: string,
          owner?: string | null,
          ownerName: string,
          questionId?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      editors?: string | null,
      favourite?: boolean | null,
      id: string,
      owner?: string | null,
      question: string,
      updatedAt: string,
    } | null,
    questionId?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateInitiativeSubscriptionVariables = {
  filter?: ModelSubscriptionInitiativeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateInitiativeSubscription = {
  onUpdateInitiative?:  {
    __typename: "Initiative",
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        initiative?:  {
          __typename: "Initiative",
          cloudEnvironments?: Array< CloudEnvironment | null > | null,
          cloudProvider: CloudProvider,
          costUnit?: string | null,
          createdAt: string,
          description: string,
          editors?: Array< string | null > | null,
          id: string,
          key: string,
          lastEditor?: string | null,
          name: string,
          owner?: string | null,
          ownerName?: string | null,
          phase: AuditPhase,
          serviceType: CloudServiceType,
          status: InitiativeStatus,
          updatedAt: string,
        } | null,
        initiativeId?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        lastEditor?: string | null,
        name: string,
        originAuditId?: string | null,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    cloudEnvironments?: Array< CloudEnvironment | null > | null,
    cloudProvider: CloudProvider,
    costUnit?: string | null,
    createdAt: string,
    description: string,
    editors?: Array< string | null > | null,
    id: string,
    key: string,
    lastEditor?: string | null,
    name: string,
    owner?: string | null,
    ownerName?: string | null,
    phase: AuditPhase,
    projectOwner?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    projectResponsible?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    riscOfficer?:  {
      __typename: "EntraIDUser",
      department?: string | null,
      displayName: string,
      id: string,
      mail: string,
    } | null,
    serviceType: CloudServiceType,
    status: InitiativeStatus,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuestionSubscriptionVariables = {
  editors?: string | null,
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    answer:  {
      __typename: "Answer",
      type?: AnswerType | null,
      values:  Array< {
        __typename: "AnswerValue",
        auditReferences: Array< string | null >,
        selected: boolean,
        value: string,
      } >,
    },
    audit?:  {
      __typename: "Audit",
      createdAt: string,
      description: string,
      editors?: Array< string | null > | null,
      id: string,
      initiative?:  {
        __typename: "Initiative",
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
        } | null,
        cloudEnvironments?: Array< CloudEnvironment | null > | null,
        cloudProvider: CloudProvider,
        costUnit?: string | null,
        createdAt: string,
        description: string,
        editors?: Array< string | null > | null,
        id: string,
        key: string,
        lastEditor?: string | null,
        name: string,
        owner?: string | null,
        ownerName?: string | null,
        phase: AuditPhase,
        projectOwner?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        projectResponsible?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        riscOfficer?:  {
          __typename: "EntraIDUser",
          department?: string | null,
          displayName: string,
          id: string,
          mail: string,
        } | null,
        serviceType: CloudServiceType,
        status: InitiativeStatus,
        updatedAt: string,
      } | null,
      initiativeId?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      lastEditor?: string | null,
      name: string,
      originAuditId?: string | null,
      owner?: string | null,
      ownerName?: string | null,
      phase: AuditPhase,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    auditId?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        article?:  {
          __typename: "Article",
          active: boolean,
          content: string,
          createdAt: string,
          id: string,
          introduction: string,
          pinned: boolean,
          thumbnail?: string | null,
          title: string,
          topics: Array< string >,
          updatedAt: string,
        } | null,
        articleId?: string | null,
        content: string,
        createdAt: string,
        id: string,
        owner?: string | null,
        ownerName: string,
        question?:  {
          __typename: "Question",
          auditId?: string | null,
          createdAt: string,
          description?: string | null,
          editors?: string | null,
          favourite?: boolean | null,
          id: string,
          owner?: string | null,
          question: string,
          updatedAt: string,
        } | null,
        questionId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    editors?: string | null,
    favourite?: boolean | null,
    id: string,
    owner?: string | null,
    question: string,
    updatedAt: string,
  } | null,
};

export type GetCustomAuditQueryVariables = {
  id: string,
};

export type GetCustomAuditQuery = {
  getAudit?:  {
    __typename: "Audit",
    name: string,
    description: string,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        question: string,
        description?: string | null,
        answer:  {
          __typename: "Answer",
          type?: AnswerType | null,
          values:  Array< {
            __typename: "AnswerValue",
            value: string,
            selected: boolean,
            auditReferences: Array< string | null >,
          } >,
        },
      } | null >,
    } | null,
  } | null,
};

export type listAuditsWithBasicDataQueryVariables = {
  filter?: ModelAuditFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listAuditsWithBasicDataQuery = {
  listAudits?:  {
    __typename: "ModelAuditConnection",
    items:  Array< {
      __typename: "Audit",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
    } | null >,
    nextToken?: string | null,
  } | null,
};
