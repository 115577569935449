import {useCallback, useContext, useEffect, useState} from "react";
import {UserContext} from "shared/context/UserContext";
import {UserGroups} from "../../helpers/UserGroups";

export const useUserDataService = () => {
    const context = useContext(UserContext);
    const [payload, setPayload] = useState<any>();

    useEffect(() => {
        setPayload(context?.session?.tokens?.idToken?.payload);
    }, [context]);

    const getEmail = useCallback(() => {
        if (payload && payload["email"]) {
            return payload["email"].toString()
        }
        return "";
    }, [payload]);

    const getGroups = useCallback(() => {
        if (payload) {
            return payload["custom:azure_groups"] as Array<string>
        }
        return [];
    }, [payload]);

    const isAdmin = useCallback(() => {
        return getGroups().includes(UserGroups.ADMIN);
    }, [getGroups]);

    const getFullName = useCallback(() => {
        if (payload && payload["given_name"] && payload["family_name"]) {
            return payload["given_name"].toString() + " " + payload["family_name"].toString()
        }
        return getEmail();
    }, [getEmail, payload]);

    const getUsername = useCallback(() => {
        if (payload && payload["cognito:username"]) {
            return payload["cognito:username"].toString()
        }
        return getEmail();
    }, [getEmail, payload]);

    return {getEmail, getGroups, getFullName, isAdmin, getUsername};
}
