import {useUserContext} from "shared/context/UserContext";
import {GraphQLQuery} from "@aws-amplify/api";
import {Audit, AuditPhase, CreateAuditInput, CreateAuditMutation, GetAuditQuery, ListAuditsQuery, ListAuditsQueryVariables, UpdateAuditInput, UpdateAuditMutation} from "shared/graphql/API";
import {getCustomAudit, listAuditsWithBasicData} from "../../graphql/custom/custom-queries";
import {createAudit as createAuditOperation, updateAudit as updateAuditOperation} from "../../graphql/mutations";
import {Notification} from "shared/helpers/notification/Notification";
import {useCallback} from "react";
import {getAudit as getAuditOperation, listAuditByInitiativeId, listAudits as listAuditsOperation} from "../../graphql/queries";
import {useQuestionService} from "./QuestionService";
import {ListAuditByInitiativeIdQuery} from "../../graphql/API";

export const useAuditService = () => {
    const {createQuestions} = useQuestionService();
    const {client} = useUserContext();

    const createAudit = useCallback(async (input: CreateAuditInput): Promise<Audit> => {
        const {data} = await client.graphql<GraphQLQuery<CreateAuditMutation>>({
            query: createAuditOperation,
            variables: {input: input},
        });
        return data?.createAudit as Audit;
    }, [client]);

    const listAudits = useCallback(async (input: ListAuditsQueryVariables = {}): Promise<Array<Audit>> => {
        const {data} = await client.graphql<GraphQLQuery<ListAuditsQuery>>({
            query: listAuditsOperation,
            variables: {input: input},
        });
        return data?.listAudits?.items as Array<Audit>;
    }, [client]);

    const listAuditsBasic = useCallback(async (variables: ListAuditsQueryVariables = {}): Promise<Array<Audit>> => {
        const {data} = await client.graphql<GraphQLQuery<ListAuditsQuery>>({
            query: listAuditsWithBasicData,
            variables: variables,
        });
        return data?.listAudits?.items as Array<Audit>;
    }, [client]);

    const retrieveAudit = useCallback(async (id: string): Promise<Audit> => {
        const {data} = await client.graphql<GraphQLQuery<GetAuditQuery>>({
            query: getCustomAudit,
            variables: {id: id},
        });
        return data?.getAudit as Audit;
    }, [client]);

    const getAudit = useCallback(async (auditId: string): Promise<Audit> => {
        const {data} = await client.graphql<GraphQLQuery<GetAuditQuery>>({
            query: getAuditOperation,
            variables: {id: auditId},
        });
        return data?.getAudit as Audit;
    }, [client]);

    const getAuditsByInitiativeID = useCallback(async (initiativeId: string): Promise<Array<Audit>> => {
        const {data} = await client.graphql<GraphQLQuery<ListAuditByInitiativeIdQuery>>({
            query: listAuditByInitiativeId,
            variables: {initiativeID: initiativeId},
        });
        return data?.listAuditByInitiativeId?.items as Array<Audit>;
    }, [client]);

    const updateAudit = useCallback(async (input: UpdateAuditInput): Promise<Audit> => {
        const {data} = await client.graphql<GraphQLQuery<UpdateAuditMutation>>({
            query: updateAuditOperation,
            variables: {input: input},
        });
        return data?.updateAudit as Audit;
    }, [client]);

    const appendAudit = useCallback(async (templateId: string, initiativeId: string): Promise<void> => {
        if (templateId) {
            let templateAudit = await retrieveAudit(templateId) as Audit;
            if (templateAudit) {
                let {questions, ...newAuditInput} = templateAudit;
                newAuditInput.isTemplate = false;
                newAuditInput.isActive = true;
                newAuditInput.originAuditId = templateId;
                newAuditInput.initiativeId = initiativeId;
                const audit = await createAudit(newAuditInput);
                if (questions && audit) {
                    await createQuestions(questions.items, audit.id);
                }
            }
        }
    }, [createAudit, createQuestions, retrieveAudit]);

    const listTemplateAudits = useCallback(async (phase: AuditPhase | null): Promise<Array<Audit>> => {
        let listTemplateAudits: ListAuditsQueryVariables = {
            filter: {
                isActive: {eq: true},
                isTemplate: {eq: true}
            }
        }
        if (phase && listTemplateAudits.filter) {
            listTemplateAudits.filter.phase = {eq: phase};
        }
        return listAuditsBasic(listTemplateAudits);
    }, [listAuditsBasic]);

    /*** Clones the template audits with a given phase and adds them to a initiative and persists cloned data. Clones also template audit questions
     *
     * @param {string} initiativeId - ACAP InitiativeID, where the template audits will be applied inside a database.
     * @param {AuditPhase} phase - all audits with a given audit phase will be added to a defined initiative. Default AuditPhase.basic.
     * ***/
    const appendTemplateAudits = useCallback(async (initiativeId: string, phase: AuditPhase = AuditPhase.basic): Promise<void> => {
        try {
            const templates = await listTemplateAudits(phase);
            if (templates) {
                for (const template of templates) {
                    await appendAudit(template.id, initiativeId);
                }
            }
        } catch (e) {
            Notification.error("Could not create new initiative.")
        }
    }, [appendAudit, listTemplateAudits]);

    return {getAuditsByInitiativeID, retrieveAudit, appendAudit, updateAudit, appendTemplateAudits, listTemplateAudits, listAudits, getAudit, createAudit};
}
