import {AnswerInput, Initiative, Question, UpdateQuestionInput} from "shared/graphql/API";
import AuditAnswer from "../audit-answer/AuditAnswer";
import React, {ReducerState, useReducer, useState} from "react";
import {Notification} from "shared/helpers/notification/Notification";
import classNames from "classnames";

import styles from "./carousel/question-carousel.module.scss";
import {Badge, Card, CardActions, CardContent, CardHeader, Chip, Fade, IconButton, Popper, TextField, Tooltip, Typography} from "@mui/material";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import CommentIcon from '@mui/icons-material/Comment';
import InfoIcon from "@mui/icons-material/Info";
import {useQuestionService} from "shared/hooks/services/QuestionService";
import {useInitiativeData} from "shared/context/InitiativeContext";
import {useInitiativeService} from "shared/hooks/services/InitiativeService";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {Sidebar} from "../../sidebar/Sidebar";

type AuditQuestionProps = {
    questionData?: Question,
    className: any,
    editMode?: boolean | undefined,
    onChange: Function,
    onCreate?: Function,
    onDelete?: Function,
}

const stateReducer = (state: Question, action: Question | any) => ({
    ...state,
    ...(typeof action === "function" ? action(state) : action),
});

function AuditQuestion(props: AuditQuestionProps) {
    const {questionData, className} = props;
    const {onChange} = props;
    const {t} = useTranslation();
    const {setUpdating, currentInitiative, currentPhase, setInitiative} = useInitiativeData();
    const {updateInitiative} = useInitiativeService();
    const {updateQuestion} = useQuestionService();
    const [question, setQuestion] = useReducer(stateReducer, questionData as ReducerState<any>);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
    const [arrowEl, setArrowEl] = React.useState<HTMLElement | null>();

    const updateAuditQuestion = (updateQuestionInput: UpdateQuestionInput) => {
        updateQuestion(updateQuestionInput)
            .then((d) => {
                setQuestion(d);
                onChange(d);
            })
            .then(() => Notification.success("Question updated."))
            .then(() => {
                if (currentInitiative && currentInitiative?.phase !== currentPhase) {
                    updateInitiative({
                        id: currentInitiative.id,
                        phase: currentPhase
                    }).then((c: Initiative) => setInitiative(c));
                }
            })
            .catch(() => {
                Notification.error("Could not update question information.");
            })
            .finally(() => setUpdating(false));
    }

    const lastUpdatedAt = () => {
        if (question?.updatedAt) {
            const i18nPrefix = t("content.common.updatedAt");
            const updatedAt = i18nPrefix + dayjs(question.updatedAt).fromNow();
            return <Tooltip title={dayjs(question.updatedAt).format().toString()}>
                <Chip className={styles.cardChip} label={updatedAt}/>
            </Tooltip>
        }
    }

    const renderAuditToolbar = () => {
        return <div>
            <IconButton onClick={() => setShowInfo(!showInfo)} color={"info"} ref={(r) => setAnchorEl(r)}>
                <InfoIcon color={showInfo ? "warning" : "info"}/>
            </IconButton>
            <Popper id={question.id}
                    className={styles.popper}
                    open={showInfo}
                    anchorEl={anchorEl}
                    transition
                    placement={"bottom-end"}
                    disablePortal={false}
                    modifiers={[
                        {
                            name: "arrow",
                            enabled: true,
                            options: {
                                element: arrowEl,
                            }
                        }
                    ]}>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={500}>
                        <div>
                            <span className={styles.arrow} ref={(e) => setArrowEl(e)}/>
                            <Card variant={"outlined"}>
                                <CardHeader title={t("content.common.questionnaire")}
                                            subheader={question.audit.name}
                                            titleTypographyProps={{variant: "body1"}}
                                />
                                <CardContent>
                                    <TextField multiline disabled value={question.description} fullWidth/>
                                </CardContent>
                                <CardActions>
                                    {lastUpdatedAt()}
                                </CardActions>
                            </Card>
                        </div>
                    </Fade>
                )}
            </Popper>
            <IconButton
                onClick={() => updateAuditQuestion({
                    favourite: !question?.favourite,
                    id: question?.id,
                })}>
                <TurnedInIcon className={question?.favourite ? classNames(styles.favouriteIconSelected, styles.acapIcon) : classNames(styles.favouriteIconDeselected, styles.acapIcon)}/>
            </IconButton>
            <IconButton aria-label="cart" onClick={() => Sidebar.showComments({title: question.question, scope: {questionId: question.id}})}>
                <Badge badgeContent={question.comments.items.length} color="primary">
                    <CommentIcon/>
                </Badge>
            </IconButton>
        </div>
    }

    const renderQuestion = () => {
        return <Card className={classNames(className, styles.card)}>
            <CardHeader action={renderAuditToolbar()}/>
            <CardContent className={styles.cardContainer}>
                <div className={styles.cardColumn}>
                    <div className={styles.cardRow}>
                        <Typography>
                            {question.question}
                        </Typography>
                    </div>
                    <div className={styles.cardRow}>
                        <AuditAnswer answer={question.answer}
                                     onChange={
                                         (a: AnswerInput) => updateAuditQuestion({
                                             answer: a,
                                             id: question.id,
                                         })
                                     }
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    }
    return renderQuestion();
}

export default AuditQuestion;

