import SvgIcon from '@mui/material/SvgIcon';

type GermanyFlagProps = {
    [x: string]: any;
}

export const GermanyFlagIcon = (props: GermanyFlagProps) => {
    return <SvgIcon {...props}>
        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" fill={"currentColor"}>
            <path id="path6155" d="M0,12.8h32v-6.4H0v6.4Z" fill="#11100f" fillRule="evenodd" strokeWidth="0"/>
            <path id="path6157" d="M0,19.2h32v-6.4H0v6.4Z" fill="#dc2326" fillRule="evenodd" strokeWidth="0"/>
            <path id="path6159" d="M0,25.61h32v-6.4H0v6.4Z" fill="#ecdd04" fillRule="evenodd" strokeWidth="0"/>
        </svg>
    </SvgIcon>
}


