/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createArticle = /* GraphQL */ `mutation CreateArticle(
  $condition: ModelArticleConditionInput
  $input: CreateArticleInput!
) {
  createArticle(condition: $condition, input: $input) {
    active
    comments {
      items {
        article {
          active
          content
          createdAt
          id
          introduction
          pinned
          thumbnail
          title
          topics
          updatedAt
          __typename
        }
        articleId
        content
        createdAt
        id
        owner
        ownerName
        question {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        questionId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    content
    createdAt
    id
    introduction
    pinned
    thumbnail
    title
    topics
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateArticleMutationVariables,
  APITypes.CreateArticleMutation
>;
export const createAudit = /* GraphQL */ `mutation CreateAudit(
  $condition: ModelAuditConditionInput
  $input: CreateAuditInput!
) {
  createAudit(condition: $condition, input: $input) {
    createdAt
    description
    editors
    id
    initiative {
      audits {
        items {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cloudEnvironments
      cloudProvider
      costUnit
      createdAt
      description
      editors
      id
      key
      lastEditor
      name
      owner
      ownerName
      phase
      projectOwner {
        department
        displayName
        id
        mail
        __typename
      }
      projectResponsible {
        department
        displayName
        id
        mail
        __typename
      }
      riscOfficer {
        department
        displayName
        id
        mail
        __typename
      }
      serviceType
      status
      updatedAt
      __typename
    }
    initiativeId
    isActive
    isTemplate
    lastEditor
    name
    originAuditId
    owner
    ownerName
    phase
    questions {
      items {
        answer {
          type
          __typename
        }
        audit {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        auditId
        comments {
          nextToken
          __typename
        }
        createdAt
        description
        editors
        favourite
        id
        owner
        question
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAuditMutationVariables,
  APITypes.CreateAuditMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $condition: ModelCommentConditionInput
  $input: CreateCommentInput!
) {
  createComment(condition: $condition, input: $input) {
    article {
      active
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content
      createdAt
      id
      introduction
      pinned
      thumbnail
      title
      topics
      updatedAt
      __typename
    }
    articleId
    content
    createdAt
    id
    owner
    ownerName
    question {
      answer {
        type
        values {
          auditReferences
          selected
          value
          __typename
        }
        __typename
      }
      audit {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      auditId
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      description
      editors
      favourite
      id
      owner
      question
      updatedAt
      __typename
    }
    questionId
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const createInitiative = /* GraphQL */ `mutation CreateInitiative(
  $condition: ModelInitiativeConditionInput
  $input: CreateInitiativeInput!
) {
  createInitiative(condition: $condition, input: $input) {
    audits {
      items {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    cloudEnvironments
    cloudProvider
    costUnit
    createdAt
    description
    editors
    id
    key
    lastEditor
    name
    owner
    ownerName
    phase
    projectOwner {
      department
      displayName
      id
      mail
      __typename
    }
    projectResponsible {
      department
      displayName
      id
      mail
      __typename
    }
    riscOfficer {
      department
      displayName
      id
      mail
      __typename
    }
    serviceType
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInitiativeMutationVariables,
  APITypes.CreateInitiativeMutation
>;
export const createQuestion = /* GraphQL */ `mutation CreateQuestion(
  $condition: ModelQuestionConditionInput
  $input: CreateQuestionInput!
) {
  createQuestion(condition: $condition, input: $input) {
    answer {
      type
      values {
        auditReferences
        selected
        value
        __typename
      }
      __typename
    }
    audit {
      createdAt
      description
      editors
      id
      initiative {
        audits {
          nextToken
          __typename
        }
        cloudEnvironments
        cloudProvider
        costUnit
        createdAt
        description
        editors
        id
        key
        lastEditor
        name
        owner
        ownerName
        phase
        projectOwner {
          department
          displayName
          id
          mail
          __typename
        }
        projectResponsible {
          department
          displayName
          id
          mail
          __typename
        }
        riscOfficer {
          department
          displayName
          id
          mail
          __typename
        }
        serviceType
        status
        updatedAt
        __typename
      }
      initiativeId
      isActive
      isTemplate
      lastEditor
      name
      originAuditId
      owner
      ownerName
      phase
      questions {
        items {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    auditId
    comments {
      items {
        article {
          active
          content
          createdAt
          id
          introduction
          pinned
          thumbnail
          title
          topics
          updatedAt
          __typename
        }
        articleId
        content
        createdAt
        id
        owner
        ownerName
        question {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        questionId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    description
    editors
    favourite
    id
    owner
    question
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionMutationVariables,
  APITypes.CreateQuestionMutation
>;
export const deleteArticle = /* GraphQL */ `mutation DeleteArticle(
  $condition: ModelArticleConditionInput
  $input: DeleteArticleInput!
) {
  deleteArticle(condition: $condition, input: $input) {
    active
    comments {
      items {
        article {
          active
          content
          createdAt
          id
          introduction
          pinned
          thumbnail
          title
          topics
          updatedAt
          __typename
        }
        articleId
        content
        createdAt
        id
        owner
        ownerName
        question {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        questionId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    content
    createdAt
    id
    introduction
    pinned
    thumbnail
    title
    topics
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArticleMutationVariables,
  APITypes.DeleteArticleMutation
>;
export const deleteAudit = /* GraphQL */ `mutation DeleteAudit(
  $condition: ModelAuditConditionInput
  $input: DeleteAuditInput!
) {
  deleteAudit(condition: $condition, input: $input) {
    createdAt
    description
    editors
    id
    initiative {
      audits {
        items {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cloudEnvironments
      cloudProvider
      costUnit
      createdAt
      description
      editors
      id
      key
      lastEditor
      name
      owner
      ownerName
      phase
      projectOwner {
        department
        displayName
        id
        mail
        __typename
      }
      projectResponsible {
        department
        displayName
        id
        mail
        __typename
      }
      riscOfficer {
        department
        displayName
        id
        mail
        __typename
      }
      serviceType
      status
      updatedAt
      __typename
    }
    initiativeId
    isActive
    isTemplate
    lastEditor
    name
    originAuditId
    owner
    ownerName
    phase
    questions {
      items {
        answer {
          type
          __typename
        }
        audit {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        auditId
        comments {
          nextToken
          __typename
        }
        createdAt
        description
        editors
        favourite
        id
        owner
        question
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAuditMutationVariables,
  APITypes.DeleteAuditMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment(
  $condition: ModelCommentConditionInput
  $input: DeleteCommentInput!
) {
  deleteComment(condition: $condition, input: $input) {
    article {
      active
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content
      createdAt
      id
      introduction
      pinned
      thumbnail
      title
      topics
      updatedAt
      __typename
    }
    articleId
    content
    createdAt
    id
    owner
    ownerName
    question {
      answer {
        type
        values {
          auditReferences
          selected
          value
          __typename
        }
        __typename
      }
      audit {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      auditId
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      description
      editors
      favourite
      id
      owner
      question
      updatedAt
      __typename
    }
    questionId
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const deleteInitiative = /* GraphQL */ `mutation DeleteInitiative(
  $condition: ModelInitiativeConditionInput
  $input: DeleteInitiativeInput!
) {
  deleteInitiative(condition: $condition, input: $input) {
    audits {
      items {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    cloudEnvironments
    cloudProvider
    costUnit
    createdAt
    description
    editors
    id
    key
    lastEditor
    name
    owner
    ownerName
    phase
    projectOwner {
      department
      displayName
      id
      mail
      __typename
    }
    projectResponsible {
      department
      displayName
      id
      mail
      __typename
    }
    riscOfficer {
      department
      displayName
      id
      mail
      __typename
    }
    serviceType
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInitiativeMutationVariables,
  APITypes.DeleteInitiativeMutation
>;
export const deleteQuestion = /* GraphQL */ `mutation DeleteQuestion(
  $condition: ModelQuestionConditionInput
  $input: DeleteQuestionInput!
) {
  deleteQuestion(condition: $condition, input: $input) {
    answer {
      type
      values {
        auditReferences
        selected
        value
        __typename
      }
      __typename
    }
    audit {
      createdAt
      description
      editors
      id
      initiative {
        audits {
          nextToken
          __typename
        }
        cloudEnvironments
        cloudProvider
        costUnit
        createdAt
        description
        editors
        id
        key
        lastEditor
        name
        owner
        ownerName
        phase
        projectOwner {
          department
          displayName
          id
          mail
          __typename
        }
        projectResponsible {
          department
          displayName
          id
          mail
          __typename
        }
        riscOfficer {
          department
          displayName
          id
          mail
          __typename
        }
        serviceType
        status
        updatedAt
        __typename
      }
      initiativeId
      isActive
      isTemplate
      lastEditor
      name
      originAuditId
      owner
      ownerName
      phase
      questions {
        items {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    auditId
    comments {
      items {
        article {
          active
          content
          createdAt
          id
          introduction
          pinned
          thumbnail
          title
          topics
          updatedAt
          __typename
        }
        articleId
        content
        createdAt
        id
        owner
        ownerName
        question {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        questionId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    description
    editors
    favourite
    id
    owner
    question
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionMutationVariables,
  APITypes.DeleteQuestionMutation
>;
export const updateArticle = /* GraphQL */ `mutation UpdateArticle(
  $condition: ModelArticleConditionInput
  $input: UpdateArticleInput!
) {
  updateArticle(condition: $condition, input: $input) {
    active
    comments {
      items {
        article {
          active
          content
          createdAt
          id
          introduction
          pinned
          thumbnail
          title
          topics
          updatedAt
          __typename
        }
        articleId
        content
        createdAt
        id
        owner
        ownerName
        question {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        questionId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    content
    createdAt
    id
    introduction
    pinned
    thumbnail
    title
    topics
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateArticleMutationVariables,
  APITypes.UpdateArticleMutation
>;
export const updateAudit = /* GraphQL */ `mutation UpdateAudit(
  $condition: ModelAuditConditionInput
  $input: UpdateAuditInput!
) {
  updateAudit(condition: $condition, input: $input) {
    createdAt
    description
    editors
    id
    initiative {
      audits {
        items {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cloudEnvironments
      cloudProvider
      costUnit
      createdAt
      description
      editors
      id
      key
      lastEditor
      name
      owner
      ownerName
      phase
      projectOwner {
        department
        displayName
        id
        mail
        __typename
      }
      projectResponsible {
        department
        displayName
        id
        mail
        __typename
      }
      riscOfficer {
        department
        displayName
        id
        mail
        __typename
      }
      serviceType
      status
      updatedAt
      __typename
    }
    initiativeId
    isActive
    isTemplate
    lastEditor
    name
    originAuditId
    owner
    ownerName
    phase
    questions {
      items {
        answer {
          type
          __typename
        }
        audit {
          createdAt
          description
          editors
          id
          initiativeId
          isActive
          isTemplate
          lastEditor
          name
          originAuditId
          owner
          ownerName
          phase
          updatedAt
          __typename
        }
        auditId
        comments {
          nextToken
          __typename
        }
        createdAt
        description
        editors
        favourite
        id
        owner
        question
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAuditMutationVariables,
  APITypes.UpdateAuditMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $condition: ModelCommentConditionInput
  $input: UpdateCommentInput!
) {
  updateComment(condition: $condition, input: $input) {
    article {
      active
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content
      createdAt
      id
      introduction
      pinned
      thumbnail
      title
      topics
      updatedAt
      __typename
    }
    articleId
    content
    createdAt
    id
    owner
    ownerName
    question {
      answer {
        type
        values {
          auditReferences
          selected
          value
          __typename
        }
        __typename
      }
      audit {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      auditId
      comments {
        items {
          articleId
          content
          createdAt
          id
          owner
          ownerName
          questionId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      description
      editors
      favourite
      id
      owner
      question
      updatedAt
      __typename
    }
    questionId
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const updateInitiative = /* GraphQL */ `mutation UpdateInitiative(
  $condition: ModelInitiativeConditionInput
  $input: UpdateInitiativeInput!
) {
  updateInitiative(condition: $condition, input: $input) {
    audits {
      items {
        createdAt
        description
        editors
        id
        initiative {
          cloudEnvironments
          cloudProvider
          costUnit
          createdAt
          description
          editors
          id
          key
          lastEditor
          name
          owner
          ownerName
          phase
          serviceType
          status
          updatedAt
          __typename
        }
        initiativeId
        isActive
        isTemplate
        lastEditor
        name
        originAuditId
        owner
        ownerName
        phase
        questions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    cloudEnvironments
    cloudProvider
    costUnit
    createdAt
    description
    editors
    id
    key
    lastEditor
    name
    owner
    ownerName
    phase
    projectOwner {
      department
      displayName
      id
      mail
      __typename
    }
    projectResponsible {
      department
      displayName
      id
      mail
      __typename
    }
    riscOfficer {
      department
      displayName
      id
      mail
      __typename
    }
    serviceType
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInitiativeMutationVariables,
  APITypes.UpdateInitiativeMutation
>;
export const updateQuestion = /* GraphQL */ `mutation UpdateQuestion(
  $condition: ModelQuestionConditionInput
  $input: UpdateQuestionInput!
) {
  updateQuestion(condition: $condition, input: $input) {
    answer {
      type
      values {
        auditReferences
        selected
        value
        __typename
      }
      __typename
    }
    audit {
      createdAt
      description
      editors
      id
      initiative {
        audits {
          nextToken
          __typename
        }
        cloudEnvironments
        cloudProvider
        costUnit
        createdAt
        description
        editors
        id
        key
        lastEditor
        name
        owner
        ownerName
        phase
        projectOwner {
          department
          displayName
          id
          mail
          __typename
        }
        projectResponsible {
          department
          displayName
          id
          mail
          __typename
        }
        riscOfficer {
          department
          displayName
          id
          mail
          __typename
        }
        serviceType
        status
        updatedAt
        __typename
      }
      initiativeId
      isActive
      isTemplate
      lastEditor
      name
      originAuditId
      owner
      ownerName
      phase
      questions {
        items {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    auditId
    comments {
      items {
        article {
          active
          content
          createdAt
          id
          introduction
          pinned
          thumbnail
          title
          topics
          updatedAt
          __typename
        }
        articleId
        content
        createdAt
        id
        owner
        ownerName
        question {
          auditId
          createdAt
          description
          editors
          favourite
          id
          owner
          question
          updatedAt
          __typename
        }
        questionId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    description
    editors
    favourite
    id
    owner
    question
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionMutationVariables,
  APITypes.UpdateQuestionMutation
>;
