import React from "react"
import styles from "./footer.module.scss"
import {Box, Typography} from "@mui/material";

import {useThemeColorModeContext} from "@vhv/react-mui-theme/dist/hooks/useThemeMode";
import {vhvGrey} from "@vhv/react-mui-theme/dist";

export const Footer = () => {
    const context = useThemeColorModeContext();
    const currentYear = new Date().getFullYear();
    return <footer className={styles.footer}>
        <Box className={styles.flexContainer}
             color={context.mode === 'dark' ? vhvGrey[200] : vhvGrey[500]}
             bgcolor={context.mode === 'dark' ? vhvGrey[800] : "white"}>
            <p role="note">
                {currentYear} &copy; VHV Versicherung
            </p>
            <div className={styles.flexItem}>
                <a href={"/"} target="_blank" rel="noopener noreferrer">
                    <Typography>Terms</Typography>
                </a>
                <a href={"/"} target="_blank" rel="noopener noreferrer">
                    <Typography>notice</Typography>

                </a>
                <a href={"/"} target="_blank" rel="noopener noreferrer">
                    <Typography>dataProtection</Typography>
                </a>
                <a href={"/attribution-credits"} rel="noopener noreferrer">
                    <Typography>Quellen</Typography>
                </a>

            </div>
        </Box>
    </footer>
}
