import React from "react";
import styles from "app/styles/styles.module.scss";
import {Card} from "@mui/material";

export const CreditsPage = () => {
    return <Card className={styles.card}>
        <div>
            <a target="_blank"
               href="https://www.freepik.com/free-vector/oops-404-error-with-broken-robot-concept-illustration_13315300.htm#query=404%20found&position=0&from_view=search&track=ais" rel="noreferrer">
                404 Image by storyset</a> on Freepik
        </div>
        <div>
            <a target="_blank"
               href="https://icons8.com/icon/blLagk1rxZGp/microsoft-teams-2019" rel="noreferrer">
                Microsoft Teams 2019</a> icon by <a target="_blank" href="https://icons8.com" rel="noreferrer">Icons8</a>
        </div>
    </Card>
}
