import styles from "../../home-page.module.scss";
import {Grid, Grow, Typography} from "@mui/material";
import React, {ReactNode, useRef} from "react";
import useOnScreen from "shared/hooks/useOnScreen";
import {useTranslation} from "react-i18next";


type TeamProps = {
    title: string,
    children: ReactNode[],
    className?: string,
    orientation?: "left" | "right",
}

export const Team = ({title, children, className, orientation = "left"}: TeamProps) => {
    const {t} = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref, true);

    const renderTeamTitleSection = () => {
        return <div className={styles.teamTitleSection}>
            <Typography className={styles.teamTitle} variant="h4">
                {t(title)}
            </Typography>
        </div>
    }

    return <Grow in={isVisible} timeout={1000}>
        <Grid ref={ref} container className={className}>
            {orientation === "left" &&
                <Grid item xs={4}>
                    {renderTeamTitleSection()}
                </Grid>
            }
            <Grid item xs={8}>
                <Grid container spacing={2}>
                    {children && children.map((c: ReactNode, i) => <Grid key={title + i} item xs={6}>{c}</Grid>)}
                </Grid>
            </Grid>
            {orientation === "right" &&
                <Grid item xs={4}>
                    {renderTeamTitleSection()}
                </Grid>
            }
        </Grid>
    </Grow>
}
