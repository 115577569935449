import {Link, useParams} from "react-router-dom";
import {Breadcrumbs, Grow, Paper, Typography} from "@mui/material";
import styles from "./article.module.scss";
import {useEffect, useState} from "react";
import {Article} from "shared/graphql/API";
import {ArticleContent} from "./ArticleContent";
import {useArticleService} from "shared/hooks/services/ArticleService";
import {Notification} from "shared/helpers/notification/Notification";

export const ArticleView = () => {
    const {getArticle} = useArticleService();
    const {id} = useParams();
    const [article, setArticle] = useState<Article>();

    useEffect(() => {
        if(id){
            getArticle({id})
                .then(a => setArticle(a))
                .catch(e => Notification.error("Could not get article.", e))
        }
    }, [getArticle, id])

    return <Grow in timeout={1000}>
        <Paper className={styles.articleContainer} variant={"outlined"}>
            <Breadcrumbs>
                <Link color="inherit" to="/news">
                    News
                </Link>
                <Typography>{article?.title}</Typography>
            </Breadcrumbs>
            {article && <ArticleContent article={article}></ArticleContent>}
        </Paper>
    </Grow>
}
