import {Audit, AuditPhase, InitiativeStatus, UpdateAuditInput} from "shared/graphql/API";
import {useCallback} from "react";
import {omitObjectPaths} from "shared/helpers/CommonUtils";
import {useAuditService} from "./AuditService";
import {Notification} from "shared/helpers/notification/Notification";
import {useInitiativeData} from "shared/context/InitiativeContext";
import {useInitiativeService} from "./InitiativeService";


const UpdateAuditInputPropReference: UpdateAuditInput = {
    initiativeId: undefined,
    description: undefined,
    editors: undefined,
    id: "",
    isActive: undefined,
    isTemplate: undefined,
    name: undefined,
    originAuditId: undefined,
    owner: undefined,
    phase: undefined
}

export const useAuditAllocationService = () => {
    const {currentInitiative, audits, setAudits, setInitiative} = useInitiativeData();
    const {appendAudit, updateAudit} = useAuditService();
    const {updateInitiative} = useInitiativeService();

    /** When user changes an answer-selection WITH audit reference to an answer-selection WITHOUT a reference,
     * the already allocated audits should be deactivated. The allocated audits should not be deleted,
     * because otherwise already added information will be lost.
     * @param {Set<string>} auditReferences - Collection with unique audit Ids of current answer-selection references for a given AUDIT-PHASE.
     */
    const filterAuditsForDeactivation = useCallback((auditReferences: Set<string>, currentPhase: AuditPhase): Array<UpdateAuditInput> => {
        return omitObjectPaths<Audit>(audits, Object.keys(UpdateAuditInputPropReference))
            .filter(c => currentPhase === AuditPhase.basic ? c.phase === AuditPhase.thematic : 1)
            .filter(c => currentPhase === AuditPhase.thematic ? c.phase === AuditPhase.specific : 1)
            .filter(c => c.originAuditId && !auditReferences.has(c.originAuditId))
            .filter(a => a.isActive)
            .map(a => ({id: a.id, isActive: false}));
    }, [audits]);

    /** When user changes an answer-selection WITHOUT audit reference to an answer-selection WITH a reference,
     * the already allocated but deactivated audits should be reactivated. So the old allocated audits information can be reused.
     * @param {Set<string>} auditReferences - Collection with unique audit Ids of current answer-selection references for a given AUDIT-PHASE.
     */
    const filterAuditsForReactivation = useCallback((auditReferences: Set<string>): Array<UpdateAuditInput> => {
        return audits.filter((a) => a.originAuditId && !a.isActive && auditReferences.has(a.originAuditId))
            .map((a) => ({id: a.id, isActive: true}))
    }, [audits]);

    const removeAlreadyAllocatedAudits = useCallback((auditReferences: Set<string>) => {
        audits.forEach(a => a.originAuditId && auditReferences.delete(a.originAuditId));
    }, [audits]);

    const updateAllocatedAudits = useCallback(async (auditReferences: Set<string>, currentPhase: AuditPhase) => {
        const activationDataQueue = [...filterAuditsForDeactivation(auditReferences, currentPhase), ...filterAuditsForReactivation(auditReferences)];
        let updatedAudits = new Array<Audit>()
        for (const input of activationDataQueue) {
            updatedAudits.push(await updateAudit(input));
        }
        // const newAudits = audits.map(a => updatedAudits.findIndex(ua => ua.id === a.id) > -1 ? updatedAudits[updatedAudits.findIndex(ua => ua.id === a.id)] : a);
        // setAudits(newAudits);
        await removeAlreadyAllocatedAudits(auditReferences);
    }, [filterAuditsForDeactivation, filterAuditsForReactivation, removeAlreadyAllocatedAudits, updateAudit]);

    const updateInitiativeReferences = useCallback(async (auditReferences: Set<string>): Promise<void> => {
        if (currentInitiative) {
            for await (const reference of auditReferences.keys()) {
                await appendAudit(reference, currentInitiative.id);
            }
        }
    }, [appendAudit, currentInitiative]);

    const getNextPhase = (phase: AuditPhase | null | undefined) => {
        if (phase === AuditPhase.basic) {
            return AuditPhase.thematic;
        } else if (phase === AuditPhase.thematic) {
            return AuditPhase.specific;
        } else if (phase === AuditPhase.specific) {
            return AuditPhase.specific;
        }
        return AuditPhase.basic;
    }

    const getStatus = (phase: AuditPhase | null | undefined) => {
        if (phase === AuditPhase.basic || phase === AuditPhase.thematic) {
            return InitiativeStatus.inProgress;
        } else if (phase === AuditPhase.specific) {
            return InitiativeStatus.inReview;
        }
        return InitiativeStatus.open;
    }

    const updateInitiativePhase = useCallback(async (currentPhase: AuditPhase, completePhase: boolean) => {
        if (currentInitiative) {
            updateInitiative({
                id: currentInitiative.id,
                phase: completePhase ? currentPhase : getNextPhase(currentPhase),
                status: completePhase ? InitiativeStatus.inReview : getStatus(currentPhase)
            })
                .then(c => {
                    setInitiative(c);
                    setAudits(c.audits?.items);
                })
                .catch(e => Notification.error("Could not update initiative phase", e));
        }
    }, [currentInitiative, setAudits, setInitiative, updateInitiative]);

    const allocateAuditToInitiative = useCallback(async (auditReferences: Set<string>, currentPhase: AuditPhase) => {
        if (currentInitiative) {
            // Do not change execution order. auditReferences.size will change due
            const completePhase: boolean = auditReferences.size === 0;
            await updateAllocatedAudits(auditReferences, currentPhase);
            await updateInitiativeReferences(auditReferences);
            await updateInitiativePhase(currentPhase, completePhase);
        }
    }, [currentInitiative, updateAllocatedAudits, updateInitiativePhase, updateInitiativeReferences]);

    return {allocateAuditToInitiative}
}


