import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import {Amplify} from "aws-amplify";
import "@aws-amplify/auth/enable-oauth-listener";

import output from "./outputs.json"

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: output[`acap-auth-${output.environment}`].userPoolClientId,
            userPoolId: output[`acap-auth-${output.environment}`].userPoolId,
            loginWith: {
                oauth: {
                    domain: new URL(output[`acap-auth-${output.environment}`].userPoolDomain).host,
                    scopes: output[`acap-auth-${output.environment}`].scopes.split(','),
                    redirectSignIn: output[`acap-auth-${output.environment}`].redirectSignIn.split(','),
                    redirectSignOut: output[`acap-auth-${output.environment}`].redirectSignIn.split(','),
                    responseType: 'code',
                },
                username: false,
            }
        }
    },
    API: {
        GraphQL: {
            endpoint: output[`acap-appsync-${output.environment}`].awsAppsyncApiEndpoint,
            defaultAuthMode: "userPool",
            region: output[`acap-appsync-${output.environment}`].awsAppsyncRegion
        },
        REST: {
            "MicrosoftGraphApi": {
                "endpoint": output[`acap-msGraph-${output.environment}`].endpoint,
            }
        }
    }
});


// const existingConfig = Amplify.getConfig();
// Amplify.configure({
//     ...existingConfig,
//     API: {
//         ...existingConfig.API,
//         REST: outputs.custom.API,
//     },
// });

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <App/>
);

