import React, {useEffect, useState} from "react";
import {Initiative} from "shared/graphql/API";
import {useUserContext} from "shared/context/UserContext";
import InitiativeCard from "./components/initiative-card/InitiativeCard";
import {Notification} from "shared/helpers/notification/Notification";
import styles from "app/styles/styles.module.scss";
import {anyMatchForObjectKey, matchBetween, matchByToggle, SortDirection} from "shared/helpers/CommonUtils";
import classNames from "classnames";
import {
    Backdrop,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputBase,
    MenuItem,
    Paper,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {InitiativeContextProvider} from "shared/context/InitiativeContext";
import {useInitiativeService} from "shared/hooks/services/InitiativeService";
import {useTranslation} from "react-i18next";
import {Sidebar} from "../../components/sidebar/Sidebar";
import {useParams} from "react-router-dom";

export const InitiativePage = () => {
    const userContext = useUserContext();
    const {id} = useParams();
    const {t} = useTranslation();
    const {listInitiatives} = useInitiativeService();
    const [renderInitiativeCreation, setRenderInitiativeCreation] = useState(false);
    const [initiatives, setInitiatives] = useState<Array<Initiative>>([]);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [sort, setSort] = useState<SortDirection>("NONE");
    const [ownedInitiative, setOwnedInitiative] = useState(false);
    const [searchItem, setSearchItem] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Sidebar.updateInfo({title: "Initiative Page", content: t("content.homepage.welcomeSection.subtitle")});
    }, [t]);

    useEffect(() => {
        setLoading(true);
        const filter = id ? {filter: {id: {eq: id}}} : {}
        listInitiatives(filter)
            .then(c => setInitiatives(c))
            .catch(() => Notification.error("Could not retrieve initiatives information."))
            .finally(() => setLoading(false));
    }, [id, listInitiatives]);

    const addNewInitiative = (newInitiative: Initiative) => {
        setInitiatives([newInitiative, ...initiatives]);
        setRenderInitiativeCreation(false);
    }

    const renderInitiatives = () => {
        if (initiatives && initiatives.length > 0) {
            return initiatives?.filter(c => filterItem(c))
                .sort((a, b) => sort === "DESC" && (a.updatedAt < b.updatedAt) ? 1 : -1)
                .sort((a, b) => sort === "ASC" && (a.updatedAt < b.updatedAt) ? -1 : 1)
                .map(c => <InitiativeContextProvider initiativeId={c.id} key={c.id}>
                        <InitiativeCard/>
                    </InitiativeContextProvider>
                );
        } else if (!loading) {
            return <Card className={styles.card}>
                <CardContent sx={{display: "flex", flexDirection: "column"}}>
                    <Typography sx={{margin: "1rem"}}>
                        {t("content.initiative.creation.firstInitiative")}
                    </Typography>
                    <Button sx={{margin: "1rem"}} variant={"contained"} onClick={() => setRenderInitiativeCreation(!renderInitiativeCreation)}>
                        {t("content.initiative.creation.button")}
                    </Button>
                </CardContent>
            </Card>
        }
    }

    const filterItem = (object: Initiative) => {
        const search = anyMatchForObjectKey(object, ["name", "description"], searchItem)
        const byDate = matchBetween(object, "createdAt", startDate, endDate)
        const owned = matchByToggle(object, "owner", ownedInitiative, userContext.user?.username);
        return search && byDate && owned;
    }

    const clearFilter = () => {
        setSearchItem("");
        setSort("NONE");
    }

    const renderFilterBar = () => {
        return <Paper className={classNames(styles.filterContainer, styles.sticky, styles.primary)}>
            <div className={styles.filterBar}>
                <Tooltip title={t("content.initiative.filter.new")}>
                    <IconButton size="medium"
                                color={"primary"}
                                onClick={() => setRenderInitiativeCreation(!renderInitiativeCreation)}
                    >
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
                <InputBase
                    placeholder={t("action.search.initiative")}
                    onChange={(e) => setSearchItem(e.target.value)}
                    value={searchItem}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon/>
                        </InputAdornment>
                    }
                />
                <Divider orientation="vertical"/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={ownedInitiative}
                            onChange={() => setOwnedInitiative(!ownedInitiative)}/>
                    }
                    label={t("content.initiative.filter.ownedInitiative")}
                />
                <TextField className={styles.formControl} label={t("content.common.sort.title")} size="small" select value={sort} onChange={(e) => setSort(e.target.value as SortDirection)}>
                    <MenuItem value={"NONE"}>{t("content.common.sort.none")}</MenuItem>
                    <MenuItem value={"DESC"}>{t("content.common.sort.desc")}</MenuItem>
                    <MenuItem value={"ASC"}>{t("content.common.sort.asc")}</MenuItem>
                </TextField>
                <DatePicker label={t("content.common.dateFrom")}
                            slotProps={{textField: {size: 'small'}}}
                            maxDate={dayjs()}
                            onChange={(e) => setStartDate(e?.toDate())}
                />
                <DatePicker label={t("content.common.dateTo")}
                            slotProps={{textField: {size: 'small'}}}
                            maxDate={dayjs()}
                            onChange={(e) => setEndDate(e?.toDate())}
                />
                <Tooltip title={t("action.reset.filter")}>
                    <IconButton size="medium" onClick={() => clearFilter()} color={"primary"} sx={{m: 1}}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    }

    const renderInitiativePage = () => {
        if (loading) {
            return <Backdrop open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        }
        return <>
            {renderFilterBar()}
            {renderInitiativeCreation && <InitiativeContextProvider>
                <InitiativeCard onCreate={(c: Initiative) => addNewInitiative(c)}/>
            </InitiativeContextProvider>
            }
            {renderInitiatives()}
        </>
    }

    return renderInitiativePage()
}
