import {Badge, CardContent, CardHeader, IconButton} from "@mui/material";
import parse from "html-react-parser";
import {Article} from "shared/graphql/API";
import {getDayMonthDate} from "shared/helpers/CommonUtils";
import {Sidebar} from "../../sidebar/Sidebar";
import CommentIcon from "@mui/icons-material/Comment";
import React from "react";

type ArticleContentProps = {
    className?: string,
    article: Article,
}

export const ArticleContent = (props: ArticleContentProps) => {
    const {className, article} = props;
    return <div className={className}>
        <CardHeader
            title={article.title || "Title"}
            subheader={getDayMonthDate(article.createdAt)}
            action={
                <IconButton aria-label="cart" onClick={() => Sidebar.showComments({title: article.title, scope: {articleId: article.id}})}>
                    <Badge badgeContent={article.comments?.items.length} color="primary">
                        <CommentIcon/>
                    </Badge>
                </IconButton>
            }/>
        <CardContent className={"mce-content-body"}>
            <p>
                {article.introduction || "Introduction"}
            </p>
            <br/>
            {article?.content && parse(article.content)}
        </CardContent>
    </div>
}
