import React from "react";
import {ReactComponent as Logo} from "./vhv-gruppe-logo.svg";
import {NavLink} from "react-router-dom";
import styles from "./toolbar.module.scss"
import {Box, IconButton, Typography} from "@mui/material";
import {signOut} from "@aws-amplify/auth";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {SearchWidget} from "../search/SearchWidget";
import {useUserDataService} from "../../shared/hooks/services/useUserDataService";

export const AppToolbar = () => {
    const {getEmail} = useUserDataService();

    const renderSignOutButton = () => {
        if (getEmail()) {
            return <IconButton onClick={() => signOut()} color={"secondary"}>
                <ExitToAppIcon/>
            </IconButton>
        }
        return null;
    }

    return <Box className={styles.header} bgcolor={"background.default"}>
        <NavLink to={"/"} aria-label="logo" className={styles.vhvGroupLogo}>
            <Logo/>
        </NavLink>
        <SearchWidget/>
        <Typography className={styles.loggedInUsername} aria-label="username">
            {getEmail()}
        </Typography>
        {renderSignOutButton()}
    </Box>
};
