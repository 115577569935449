import React, {useEffect, useState} from "react";
import {useUserContext} from "shared/context/UserContext";
import {Audit, AuditPhase} from "shared/graphql/API";
import AuditEditor from "../../components/audit/audit-editor/AuditEditor";
import {anyMatchForObjectKey, matchByToggle, SelectionEntry} from "shared/helpers/CommonUtils";
import {Notification} from "shared/helpers/notification/Notification";
import styles from "app/styles/styles.module.scss";
import classNames from "classnames";
import {AuditAutocompletionContextProvider} from "shared/context/AuditAutocompletionContext";
import {Divider, FormControlLabel, IconButton, InputAdornment, InputBase, MenuItem, Paper, Switch, TextField, Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {useAuditService} from "shared/hooks/services/AuditService";
import {useTranslation} from "react-i18next";
import {Sidebar} from "../../components/sidebar/Sidebar";

export const AuditCatalogPage = () => {
    const userContext = useUserContext();
    const {t} = useTranslation();
    const {listAudits} = useAuditService();
    const [audits, setAudits] = useState<Array<Audit>>([]);
    const [viewCreationForm, setViewCreationForm] = useState(false);
    const [searchItem, setSearchItem] = useState<string>("");
    const [ownedAudit, setOwnedAudit] = useState(false);
    const [auditPhase, setAuditPhase] = useState<SelectionEntry>(SelectionEntry.ALL);
    const [auditIsActive, setAuditIsActive] = useState<SelectionEntry>(SelectionEntry.ALL);
    const [auditIsTemplate, setAuditIsTemplate] = useState<SelectionEntry>(SelectionEntry.TRUE);

    useEffect(() => {
        Sidebar.updateInfo({title: "Audit Catalog Page", content: t("content.homepage.welcomeSection.subtitle")});
    }, [t])

    useEffect(() => {
        listAudits()
            .then(a => setAudits(a))
            .catch((e) => Notification.error("Could not retrieve audits information.", e))
    }, [listAudits])

    const filterItem = (object: Audit) => {
        const search = anyMatchForObjectKey(object, ["name", "description"], searchItem)
        const phase = anyMatchForObjectKey(object, ["phase"], auditPhase)
        const active = anyMatchForObjectKey(object, ["isActive"], auditIsActive)
        const template = anyMatchForObjectKey(object, ["isTemplate"], auditIsTemplate);
        const owned = matchByToggle(object, "owner", ownedAudit, userContext.user?.username);
        return search && phase && active && template && owned;
    }

    const renderAudits = () => {
        return audits.filter(a => filterItem(a)).map((a) => <AuditEditor key={a.id} auditId={a.id}/>)
    }

    const addNewAudit = (newAudit: Audit) => {
        setAudits([newAudit, ...audits]);
        setViewCreationForm(false)
    }

    const clearFilter = () => {
        setSearchItem("");
        setOwnedAudit(false);
        setAuditPhase(SelectionEntry.ALL)
        setAuditIsActive(SelectionEntry.ALL)
        setAuditIsTemplate(SelectionEntry.TRUE);
    }

    const renderFilterBar = () => {
        return <Paper className={classNames(styles.filterContainer, styles.sticky, styles.primary)}>
            <div className={styles.filterBar}>
                <Tooltip title={t("content.audit.catalog.filter.createTooltip")}>
                    <IconButton size="medium"
                                color={"primary"}
                                onClick={() => setViewCreationForm(!viewCreationForm)}
                    >
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
                <InputBase
                    placeholder={t("action.search.audits")}
                    inputProps={{"aria-label": "search"}}
                    onChange={(e) => setSearchItem(e.target.value)}
                    value={searchItem}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon/>
                        </InputAdornment>
                    }
                />
                <Divider orientation="vertical"/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={ownedAudit}
                            onChange={() => setOwnedAudit(!ownedAudit)}/>
                    }
                    label={t("content.audit.catalog.filter.ownedAudit")}
                />
                <TextField className={styles.formControl} label={t("content.common.phase")} size="small" select value={auditPhase} onChange={(e) => setAuditPhase(e.target.value as SelectionEntry)}>
                    <MenuItem value={"all"}>{t("content.common.all")}</MenuItem>
                    <MenuItem value={AuditPhase.basic}>{AuditPhase.basic}</MenuItem>
                    <MenuItem value={AuditPhase.thematic}>{AuditPhase.thematic}</MenuItem>
                    <MenuItem value={AuditPhase.specific}>{AuditPhase.specific}</MenuItem>
                </TextField>
                <TextField className={styles.formControl} label={"Aktiv"} size="small" select value={auditIsActive} onChange={(e) => setAuditIsActive(e.target.value as SelectionEntry)}>
                    <MenuItem value={SelectionEntry.ALL}>{t("content.common.all")}</MenuItem>
                    <MenuItem value={SelectionEntry.TRUE}>{t("content.common.yes")}</MenuItem>
                    <MenuItem value={SelectionEntry.FALSE}>{t("content.common.no")}</MenuItem>
                </TextField>
                <TextField className={styles.formControl} label={"Template"} size="small" select value={auditIsTemplate} onChange={(e) => setAuditIsTemplate(e.target.value as SelectionEntry)}>
                    <MenuItem value={SelectionEntry.ALL}>{t("content.common.all")}</MenuItem>
                    <MenuItem value={SelectionEntry.TRUE}>{t("content.common.yes")}</MenuItem>
                    <MenuItem value={SelectionEntry.FALSE}>{t("content.common.no")}</MenuItem>
                </TextField>
                <Tooltip title={t("action.reset.filter")}>
                    <IconButton size="medium" onClick={() => clearFilter()} color={"primary"} sx={{m: 1}}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    }

    return <>
        <AuditAutocompletionContextProvider>
            {renderFilterBar()}
            {viewCreationForm && <AuditEditor onCreate={(a: Audit) => addNewAudit(a)}/>}
            {renderAudits()}
        </AuditAutocompletionContextProvider>
    </>
}
