import React, {ReactNode, useState} from "react";
import i18next from "i18next";
import {GermanyFlagIcon} from "../icons/GermanyFlagIcon";
import {IconButton, Menu, MenuItem, MenuList} from "@mui/material";
import {BritainFlagIcon} from "../icons/BritainFlagIcon";


export const LocaleSelect = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [flag, setFlag] = useState<ReactNode | null>(i18next.languages[0] === "de" ? <GermanyFlagIcon/> : <BritainFlagIcon/>);
    const [open, setOpen] = useState<boolean>(false);

    const changeLanguage = (flag: ReactNode, language: string) => {
        i18next.changeLanguage(language)
            .then(() => {
                setFlag(flag);
                setOpen(false)
            });
    }

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(Boolean(event.currentTarget));
        setAnchorEl(event.currentTarget)
    };

    const getFlag = (key: string) => {
        if (key === 'de') {
            return <GermanyFlagIcon/>
        }
    }

    const renderLanguageSelection = () => {
        return Object.keys(i18next.services.resourceStore.data).map((l: string) => <MenuItem key={l} value={l}>{getFlag(l)}</MenuItem>)
    }

    return <>
        <Menu open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                  vertical: -50,
                  horizontal: -50,
              }}
              marginThreshold={null}
              disableScrollLock={true}
              onClose={() => setOpen(false)}
        >
            <MenuList>
                <MenuItem onClick={() => changeLanguage(<GermanyFlagIcon/>, "de")}>
                    <GermanyFlagIcon/>
                </MenuItem>
                <MenuItem onClick={() => changeLanguage(<BritainFlagIcon/>, "en")}>
                    <BritainFlagIcon/>
                </MenuItem>
            </MenuList>
        </Menu>
        <IconButton color="secondary" onClick={handleClickListItem}>
            {flag}
        </IconButton>
    </>
}
