import {GraphQLQuery} from "@aws-amplify/api";
import {CreateQuestionInput, CreateQuestionMutation, DeleteQuestionInput, DeleteQuestionMutation, Question, UpdateQuestionInput, UpdateQuestionMutation} from "shared/graphql/API";
import {useCallback} from "react";
import {listQuestionByAuditId} from "../../graphql/queries";
import {createQuestion as createQuestionOperation, deleteQuestion as deleteQuestionOperation, updateQuestion as updateQuestionOperation} from "../../graphql/mutations";
import {useUserContext} from "shared/context/UserContext";
import {ListQuestionByAuditIdQuery} from "../../graphql/API";

export const useQuestionService = () => {
    const {client} = useUserContext();

    const createQuestion = useCallback(async (input: CreateQuestionInput): Promise<Question> => {
        const response = await client.graphql<GraphQLQuery<CreateQuestionMutation>>({
            query: createQuestionOperation,
            variables: {input: input},
        });
        return response.data?.createQuestion as Question
    }, [client]);

    const createQuestions = useCallback(async (questions: Array<Question | null>, auditId: string): Promise<void> => {
        for (let question of questions) {
            if (question) {
                question.auditId = auditId;
                await createQuestion(question);
            }
        }
    }, [createQuestion]);

    const deleteQuestion = useCallback(async (input: DeleteQuestionInput): Promise<Question> => {
        const response = await client.graphql<GraphQLQuery<DeleteQuestionMutation>>({
            query: deleteQuestionOperation,
            variables: {input: input},
        });
        return response.data?.deleteQuestion as Question
    }, [client]);

    const updateQuestion = useCallback(async (input: UpdateQuestionInput): Promise<Question> => {
        const response = await client.graphql<GraphQLQuery<UpdateQuestionMutation>>({
            query: updateQuestionOperation,
            variables: {input: input},
        });
        return response.data?.updateQuestion as Question
    }, [client]);

    const listQuestionsByAuditId = useCallback(async (auditId: string): Promise<Array<Question>> => {
        const {data} = await client.graphql<GraphQLQuery<ListQuestionByAuditIdQuery>>({
            query: listQuestionByAuditId,
            variables: {auditId: auditId},
        });
        return data?.listQuestionByAuditId?.items as Array<Question>;
    }, [client]);

    return {listQuestionsByAuditId, updateQuestion, createQuestion, createQuestions, deleteQuestion}
}

