import {Autocomplete, Chip, CircularProgress, ListItemText, MenuItem, TextField} from "@mui/material";
import React, {useContext, useState} from "react";
import {FilterOptionsState} from "@mui/base/useAutocomplete/useAutocomplete";
import {UserContext} from "shared/context/UserContext";
import {isNotBlank} from "shared/helpers/CommonUtils";
import {EntraIDUser} from "shared/graphql";
import {Amplify} from "aws-amplify";
import axios, {AxiosResponse} from "axios";

interface SearchRequest {
    searchText: string
}

interface SearchResult {
    "@odata.context": string,
    "@odata.count": string
    value: Array<EntraIDUser>
}

interface UserSearchProps {
    label: string,
    onChange: (user: EntraIDUser | null) => void
    readOnly?: boolean,
    value?: EntraIDUser | null,
}

export const UserSearch = (props: UserSearchProps) => {
    const {token} = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<EntraIDUser[]>([]);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>();
    const msApi = Amplify.getConfig().API.REST["MicrosoftGraphApi"];

    const searchUser = (searchText: string) => {
        setSearchLoading(true);
        if (timer)
            window.clearTimeout(timer);
        const newTimer = window.setTimeout(() => {
            setOptions([]);
            if (isNotBlank(searchText)) {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const postData: SearchRequest = {
                    searchText: searchText,
                };
                axios.post<SearchRequest, AxiosResponse<SearchResult>>(msApi.endpoint, postData, {headers})
                    .then(r => setOptions(r.data.value))
                    .finally(() => setSearchLoading(false));
            } else {
                setSearchLoading(false);
            }
        }, 500);
        setTimer(newTimer);
    }

    const filterOptions = (options: EntraIDUser[], state: FilterOptionsState<EntraIDUser>) => {
        return options.filter(o => {
            if (o.displayName && o?.mail) {
                const name = o.displayName.toLowerCase()
                const mail = o.mail.toLowerCase()
                return name.includes(state.inputValue.toLowerCase()) || mail.includes(state.inputValue.toLowerCase())
            }
            return false;
        });
    }

    return <Autocomplete
        open={open}
        value={props.value || null}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        freeSolo
        options={options}
        loading={searchLoading}
        size={"small"}
        fullWidth
        readOnly={props.readOnly}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value) => option.mail === value.mail}
        getOptionLabel={(option) => (option as EntraIDUser).displayName}
        onChange={(e, value) => props.onChange(value as EntraIDUser)}
        renderOption={(props, option) => <MenuItem {...props} key={option.mail}>
            <ListItemText primary={option.displayName} secondary={option.mail}/>
        </MenuItem>
        }
        renderInput={(params) => (
            <TextField
                {...params}
                label={props.label}
                onChange={e => searchUser(e.target.value)}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {searchLoading ? <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )}
    />
}
